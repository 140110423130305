input[type="file"]{
  color: $color-primary-dark;
  padding-top: 10px;
  padding: 10px 0 0 30px;
  height: inherit;
}
input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

input[readonly="readonly"] {
  @include user-select;
  position: relative;
  pointer-events: none;
}

input[type="file"]::before {
  content: 'Datei auswÃ¤hlen';
  color: $color-primary-dark;
  display: inline-block;
  background: transparent;
  border: $border-thick solid $color-primary-dark;
  border-radius: 5px;
  padding: 3px 10px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: none;
  font-weight: $font-weight-bold;
  transform: translate(-30px, -5px);
}
input[type="file"]:hover::before {
  border-color: $color-primary-dark;
  color:$color-primary-hover;
}
input[type="file"]:active {
  outline: 0;
}
input[type="file"]:active::before {
  border-color: $color-primary-dark;
  color:$color-primary;
}

div.form-group textarea{
  background-color: $color-secondary-light;
  border:none;
  color:$color-primary-dark;
  border-bottom: $border-thick solid $color-primary-dark;
  max-width: 100% !important;
  padding: 10px 12px;
  &:focus {
    border-color: $color-primary;
  }
}

.col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xlg-12 {
  textarea{
    width: 50%;
  }
  table{
    tr{
      td{
        textarea{
          width: 100%;
        }
      }
    }
  }
}

.col-sm-6, .col-md-6, .col-lg-6, .col-xlg-6{
  textarea{
    width: 100%;
  }
}

.form-control {
  &.disabled {
    cursor: not-allowed;
    background: $color-disabled;
    &:focus {
      border-bottom: none !important;
    }
  }
}

.no-resize {
  resize: none;
}
