body {
  &.sonata-bc {
    div.wrapper, .modal {
      table.table{
        border:none;
        thead{
          background: $color-table-head;
          color:$color-primary-dark;
          th{
            border:none;
            position: relative;
            padding-left: 11px;
            font-weight: $font-weight-bold;
            vertical-align: middle;
            &:after{
              position: absolute;
              content: "";
              width: 2px;
              height: 65%;
              right: 0px;
              top: 17.5%;
              background: $color-primary-light;
            }
            &:last-child:after{
              width: 0;
              background:transparent;
            }
            a{
              color:$color-primary-dark;
              white-space: nowrap;
              &:after, &:before{
                right: -20px;
                color: $color-primary-dark;
                font-family: "FontAwesome";
                font-size: 14px;
                content:"\f106";
                top:50%;
                margin-top: 0;
                right: -15px;
                position: unset;
                display: inherit;
                line-height: 0.8;
                transform: translate(0,-50%);
                opacity: 0.5;
              }
              &:before{
                content:"\f107";
                right: 0;
                position: absolute;
                transform: translate(0, -10%);
              }
              &:hover:after, &:hover:before{
                color: $color-primary-dark;
                right: -20px;
                top:50%;
                margin-top: 0;
                right: -15px;
                font-size: 14px;
                position: unset;
                display: inherit;
              }
              &:hover:before{
                right: 0;
                position: absolute;
                transform: translate(0, -10%);
              }
              &:hover:after{
                color:$color-primary-hover;
                opacity: 1;
              }
            }
            &.sonata-ba-list-field-header-order-desc.sonata-ba-list-field-order-active{
              a{
                &:before{
                  font-size: 14px;
                  color:$color-primary;
                  opacity: 1;
                }
                &:hover:before{
                  font-size: 14px;
                  color:$color-primary-dark;
                  opacity: 0.5;
                }
                &:hover:after{
                  font-size: 14px;
                  color:$color-primary-hover;
                  opacity: 1;
                }
              }
            }
            &.sonata-ba-list-field-header-order-asc.sonata-ba-list-field-order-active{
              a{
                &:after{
                  font-size: 14px;
                  color:$color-primary;
                  opacity: 1;
                }
                &:hover:after{
                  font-size: 14px;
                  color:$color-primary-light;
                  opacity: 0.5;
                }
                &:hover:before{
                  font-size: 14px;
                  color:$color-primary-hover;
                  opacity: 1;
                }
              }
            }
          }
        }
        tbody{

          tr{
            /*background: $color-secondary-light !important;*/
            &:nth-of-type(odd){
              background: $color-secondary !important;
              th, td{
                background: $color-secondary !important;
              }
            }
            &:nth-of-type(even){
              background: $color-secondary-light!important;
              th, td{
                background: $color-secondary-light !important;
              }
            }
            &.sonata-ba-list-row-selected{
              background-color: $color-table-selected !important;
              td, th{
                background-color: $color-table-selected !important;
              }
            }
            th{
             border-color:transparent;
              a{
                color:$color-primary-dark;
                &:hover {
                  color:$color-primary-light;
                }
              }
            }
            td{
              position: relative;
              border-color: transparent !important;
              padding-left: 10px;
              color: $color-primary-dark;
              border-right-color: transparent;
              border-left-color: transparent;
              overflow: visible;
              &:after{
                position:Â absolute;
                width:Â 2px;
                height: 80%;
                right: 0;
                top: 10%;
                background: $color-secondary-light;
                box-shadow: 2px 0px 6px rgba(0,0,0,0.05);
                content:Â "";
              }
              &:last-child:after{
                width: 0;
                background:transparent;

              }
              .label {
                display: inherit;
              }
              a{
                color: $color-primary-dark;
                font-weight: $font-weight-bold;
                &.label{
                  background: $color-primary-dark;
                  color: $color-primary-light;
                  &.label-success{
                    /*background-color: $color-primary !important;*/
                    color: #000 !important;
                    margin: auto;
                    /*line-height: 1.5;*/
                    display: block;
                    /*font-size: 1.2rem;*/
                    font-weight: 400;
                    margin-left: 0;
                  }
                }
                .label{
                  &.label-default{
                    color: $color-primary-light;
                  }
                }
                &.label-danger, &.label-success{
                  &:hover{
                    color:$color-primary !important;
                    background-color: white !important;
                  }
                }
              }
              .editable{
                border:none;
                span.label{
                  background-color: $color-primary !important;
                  color:$color-primary-dark !important;
                  /*width: 50px;*/
                  margin: auto;
                  line-height: 1.5;
                  /*display: block;*/
                  font-size: 1.2rem;
                  font-weight: $font-weight-bold;
                  margin-left: 0;
                  &.label-danger{
                    background-color:$color-primary-dark !important;
                    color:$color-primary !important;
                  }
                }
              }
              span{
                &.label{
                  background-color: $color-primary !important;
                  color:$color-primary-dark !important;
                  /*width: 50px;*/
                  margin: auto;
                  line-height: 1.5;
                  /*display: block;*/
                  font-size: 1.2rem;
                  font-weight: $font-weight-bold;
                  margin-left: 0;
                  &.label-danger{
                    background-color:$color-primary-dark !important;
                    color:$color-primary !important;
                  }
                }
              }
              .btn-group{
                display: inline-flex;
                a{
                  font-size: 0;
                  background: $color-badge;
                  border: none;
                  display: inline-block;
                  &:hover{
                    background: $color-badge-hover;
                  }
                  i{
                    font-size: 14px;
                  }
                  &:not(:last-child) {
                    &:after{
                      position: absolute;
                      content: "";
                      width: 1.5px;
                      height: 60%;
                      right: 0;
                      top: 20%;
                      background: $color-primary-dark;
                      z-index: 3;
                    }
                  }
                }
                &.btn-group-justified{
                  a{
                    display: inline-table;
                  }
                }
              }
              .field-actions{
                a{
                  &:not(:last-child) {
                    &:after{
                      position: absolute;
                      content: "";
                      width: 1.5px;
                      height: 60%;
                      right: 0;
                      top: 20%;
                      background: transparent;
                      z-index: 10;
                    }
                  }
                }
              }
              table{
                thead{
                  background: none;
                  tr{
                    background: none;
                    &:nth-of-type(odd), &:nth-of-type(even){
                      background: none;
                    }
                    th{
                      font-weight: $font-weight-bold;
                      color: $color-primary-dark;
                    }
                  }
                }
                tbody{
                  tr {
                    background: none;
                    &:nth-of-type(odd), &:nth-of-type(even){
                      background: none;
                    }
                    &:hover {
                      background: none;
                    }
                  }
                }
              }
              .label{
                &.label-info{
                  display: inherit;
                  /*line-height: 1.5;*/
                  margin-bottom: 10px;
                  /*background: $color-primary !important;*/
                  color: $color-primary-dark;
                }
              }
            }
            &:hover, &:nth-of-type(odd):hover, &:nth-of-type(even):hover{
              background: $color-table-hover !important;

              td {
                color:$color-primary-light;
                background: $color-table-hover !important;
              }

              th {
                background: $color-table-hover !important;
                a{
                  color:$color-secondary;
                  &:hover {
                    color:$color-primary;
                  }
                }
              }
            }
            &.history-audit-compare{
              .diff{
                background: $color-primary;
                color:$color-primary-dark;
              }
            }
            &.mosaic-row{
              &:hover, &:nth-of-type(odd):hover, &:nth-of-type(even):hover{
                background: transparent !important;
                td, th{
                  background: transparent !important;
                }
              }
            }
          }
        }
      }
      table.table {
        &.table-striped{
          tbody {
            tr {
              &:nth-of-type(odd) {
                background: $color-secondary-light;
              }
              &:nth-of-type(even) {
                background: $color-secondary;
              }
              td{
                border-color: #f9f9f9;
              }
            }
          }
        }
      }





      //_____________________________ table bottom area _____________________________
      form {
        div.box-body.table-responsive{
          /*overflow-x: hidden;*/
        }
        div.box-footer{
          border-color:white;
          padding-right: 0;
          label{
            color:$color-primary-dark;
            margin-right: 20px;
          }
          div.select2-container {
            .select2-choice {
              color: $color-primary-dark;
              font-weight: $font-weight-bold;
              border: $border-thick solid $color-primary-dark;
              border-radius: 5px !important;
              box-shadow: none;
              background: $color-primary-light;
            }
            &.select2-dropdown-open .select2-choice{
              border-radius: 5px 5px 0 0 !important;
              border-bottom: 1px solid $color-primary-dark;
            }
          }
          input[type=submit]{
            background: $color-primary;
            border: none;
            border-radius: 5px;
            color: $color-primary-dark;
            font-weight: $font-weight-bold;
            margin-left: 20px;
            -webkit-transition: all .1s ease-in-out;
            -moz-transition:    all .1s ease-in-out;
            -o-transition:      all .1s ease-in-out;
            -ms-transition:     all .1s ease-in-out;
            transition:         all .1s ease-in-out;
            &:hover{
              background: $color-primary-hover;
            }
          }
          .pull-right{
            font-weight: 700;
            label{
              vertical-align: unset;
            }
            button.btn-default{
              border: $border-thick solid $color-primary-dark;
              border-radius: 5px;
              background: transparent;
              color:$color-primary-dark;
              font-weight: $font-weight-bold;
            }
            .btn-group{
              position: relative;
              float: left;
              margin-right: 20px;
              &.open{
                button.btn{
                  border-radius: 5px 5px 0px 0px ;
                }
                .dropdown-toggle{
                  box-shadow: none;
                }
              }
              ul.dropdown-menu{
                clear: both;
                width: 100%;
                min-width: auto;
                border-radius: 0 0 5px 5px;
                border: 1px solid $color-primary-dark;
                margin-top: -1px;
                padding: 5px 7px;
                background: $color-primary-light;
                &>li{
                  &>a{
                    border-radius: 5px;
                    color:$color-primary-dark;
                    i {
                      display: none;
                    }
                    &:hover{
                      background: $color-primary-hover;
                      color: $color-primary-dark !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
