.popover {
  box-shadow: 0px 0px 10px #0003;
  border:none;
  .popover-title{
    background: $color-primary-light;
    font-weight: 500;
    border: none;
    border-bottom: 2px solid $color-primary;
  }
  button.btn{
    font-weight: $font-weight-bold;
    background: $color-primary-light;
    color: $color-primary-dark;
    border: $border-thick solid $color-primary-dark;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    &:hover{
      color: $color-primary-dark !important;
      background: $color-secondary-light;
    }
    &.editable-submit{
      color: $color-primary-dark !important;
      background: $color-primary;
      border-color: transparent;
      &:hover{
        background: $color-primary-hover;
      }
    }
  }
  .editable-input{
    background: $color-primary-light;
    border-radius: 5px;
    border: none;
    font-weight: 400;
    margin-top: 10px;
    select{
      border: $border-thick solid $color-primary-dark;
      border-radius: 5px;
      option{
        border: none;
      }
    }
  }
}