//_____________________________ collapsible _____________________________
#transfer-form {
  .col-md-12{
   ul {
     &.nav{
       border-bottom: 2px solid $color-primary;
       li{
         a{
           color:$color-primary-dark;
           padding: 20px 15px 20px 30px;
           &:hover, &:focus{
             background: $color-primary !important;
             color:$color-primary-light !important;
             &:after{
               color: black;
             }
           }
           &:after{
             position: absolute;
             top:50%;
             left: 5px;
             font-size: 18px;
             background: transparent;
             font-family: "FontAwesome";
             transform: translate(-0%, -50%);
             content: "\f107";
             color:$color-primary;
             opacity: 1;
           }
         }
         &[aria-expanded="true"]{
           a{
             &:after{
               transform: translate(-0%, -50%)rotate(180deg);
             }
           }
         }
       }
     }
   }
   #fair-select-button{
     margin-top: 25px;
     background: $color-primary;
     color:$color-primary-light;
     border: 1px solid $color-primary;
     &:hover{
       background: $color-primary-hover;
       color:$color-primary-light !important;
       border: 1px solid $color-primary;
     }
   }
  }
}