body{
  &.sonata-bc div.wrapper{
    background: #ffffff !important;
  }
  &.login-page{
    background: #ffffff;
    header.main-header{
      box-shadow: none;
    }
    .login-box{
      padding: 40px 60px 50px 60px;
      box-sizing: content-box;
      box-shadow: 0px 0px 22px rgba(0,0,0,0.2);
      background: $color-primary-light;
      a{
        color: $color-primary-dark;
      }
      .login-box-body{
        background: $color-primary-light;
        form{
          margin-bottom: 10px;
        }
      }
    }
    .login-logo{
      line-height: 0.9;
      padding-left: 10px;
      padding-top: 10px;
      font-weight: $font-weight-bold;
      font-size: 3.2rem;
      text-align: left;
      letter-spacing: 0.5px;
      color: $color-primary-dark;
      margin-bottom: 50px;
      margin-left: 50%;
      transform: translate(-50%,0);
      a{
        &>div{
          float: left;
        }
        span{
          padding: 10px 0 0 10px;
          display: flex;
        }
      }
    }
    .login-box-msg{
      font-size: 1.8rem;
      font-weight: $font-weight-bold;
      color:$color-primary-dark;
    }
    .form-group {
      input {
        background: $color-primary-light;
        border: none;
        border-bottom: 1px solid $color-primary-dark;
        color: $color-primary-dark;
        font-weight: $font-weight-bold;
        &:focus{
          border-color: $color-primary;
        }
      }
      .glyphicon{
        color: $color-primary-dark;
      }
    }
    .form-group:nth-child(3){
      margin-bottom: 40px;
    }
  }
}