.wrapper header.main-header{
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  height: 80px;
  .logo {
    height: 80px;
    line-height: 40px;
    padding: 15px 15px;
    border:none;
    background: $color-secondary;
    &:hover{
      background: $color-secondary-light;
    }
    span{
      font-weight: $font-weight-bold;
      width: 100px;
      text-align: left;
      letter-spacing: 0.5px;
      color:$color-primary-dark;
    }
    img{
      padding-bottom: 0;
      margin-right: 5px;
    }
  }
  .navbar {
    background: $color-secondary !important;
    .sidebar-toggle{
      border:none;
      color: $color-primary-dark;
      padding: 0px 15px 0 15px;
      margin-top: 40px;
      font-size: 2.2rem;
      transform: translate(0,-50%);
      display: none;
      &:hover{color:$color-primary-hover;background: none;}
    }

    .navbar-custom-menu{
      max-height: 80px;
    }
    .nav{
      font-size: $font-size-nav-bar;
      color: $color-primary-dark;
      font-weight: $font-weight-bold;
      margin-top: -5px;
      li {
        margin: 40px 80px 0 0;
        transform: translate(0,-50%);
        a{
          border: none !important;
          padding-right: 0;
          background: none;
          color: $color-primary-dark;
          p:nth-child(1){
            &:before{
              position: relative;
              font-family: "FontAwesome";
              content:"\f007";
              color: $color-primary-dark;
              left: -4px;
              font-size: 1.8rem;
              background: transparent;
              padding: 7px 10px;
              /*border-radius: 75px;*/
              /*border: $border-thick solid black;*/
            }
          }
          i{
            display: none;
          }
          &:hover{
            background: none;
            p:before{
              color:$color-primary-hover;
            }
          }
        }
      }
      &.breadcrumb{
        li{
          margin-top: 50%;
          transform: translate(0,-50%);
          display: contents;
          height: 80px;
          span{
            min-height: 45px;
          }
        }
      }
      .user-menu{
        .dropdown-toggle{
          background: none;
        }
        ul.dropdown-menu {
          border: none;
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
          padding: 5px 10px 5px 20px;
          margin: 10px 10px 0 0;
          background: $color-secondary-light;
          li {
            margin-top: 25px;
            a {
              color: $color-primary-dark;
              font-weight: $font-weight-bold;
            }
          }
        }
        &.open{
          a{
            p{
              color: $color-primary-dark;
            }
          }
        }
      }
    }
  }
}