body {
  font-family: "Helvetica Neue",sans-serif !important;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body .h1,
body .h2,
body .h3,
body .h4,
body .h5,
body .h6 {
  font-family: "Helvetica Neue",sans-serif !important;
}

a {
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

a:hover {
  color: #F7893A !important;
  text-decoration: none !important;
}

.ui-widget-shadow {
  background: #f5f5f5;
  color: #000;
  opacity: 1;
  border-radius: 5px;
  border: none !important;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.1) !important;
}

:focus {
  outline: transparent solid 0px !important;
}

::selection {
  background: #f93;
  color: #000;
}

button.btn.btn-primary:active:focus,
button.btn.btn-primary:active:hover {
  color: #F7893A;
  background-color: rgba(0,0,0,0.05);
  border-color: black;
}

.alert.alert-success {
  border: none;
  background-color: #f93 !important;
  border-color: #f93 !important;
}

.alert.alert-danger {
  background-color: #f93 !important;
  border-color: #f93 !important;
}

.alert.alert-info {
  background-color: #f93 !important;
  border-color: #f93 !important;
  color: #000 !important;
}

.fa-plus-circle:before {
  content: "\f067";
}

.label {
  padding: 0.4em 0.6em 0.5em;
}

.label-success {
  background-color: #2ecc71 !important;
}

.label-danger {
  background-color: #d0362a !important;
}

.checkbox label,
td label,
th label {
  font-weight: 400;
  color: #000;
}

.checkbox .icheckbox_square-blue,
td .icheckbox_square-blue,
th .icheckbox_square-blue {
  border: 1px solid rgba(0,0,0,0.7);
  box-sizing: border-box;
  background: #fff;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.checkbox .icheckbox_square-blue:hover,
td .icheckbox_square-blue:hover,
th .icheckbox_square-blue:hover {
  border-color: #F7893A;
}

.checkbox .icheckbox_square-blue.checked,
td .icheckbox_square-blue.checked,
th .icheckbox_square-blue.checked {
  border-color: #f93;
  background: #f93;
}

.checkbox .icheckbox_square-blue.checked:after,
td .icheckbox_square-blue.checked:after,
th .icheckbox_square-blue.checked:after {
  font-family: "FontAwesome";
  content: "\f00c";
  color: #fff;
  top: 50%;
  left: 50%;
  position: absolute;
  font-size: 1.1rem;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.checkbox .icheckbox_square-blue ins.iCheck-helper,
td .icheckbox_square-blue ins.iCheck-helper,
th .icheckbox_square-blue ins.iCheck-helper {
  display: none;
}

.checkbox.disabled,
td.disabled,
th.disabled {
  pointer-events: none;
}

.checkbox.disabled .icheckbox_square-blue,
td.disabled .icheckbox_square-blue,
th.disabled .icheckbox_square-blue {
  background: #aaa;
  border-color: #aaa;
}

#transfer-form .col-md-12 ul.nav li .icheckbox_square-blue {
  border: 1px solid #B5B5B5;
  border-radius: 5px;
  background: #B5B5B5;
}

#transfer-form .col-md-12 ul.nav li .icheckbox_square-blue:hover {
  border-color: #F7893A;
}

#transfer-form .col-md-12 ul.nav li .icheckbox_square-blue.checked {
  background: #f93;
  border-color: #fff;
}

#transfer-form .col-md-12 ul.nav li .icheckbox_square-blue.checked::after {
  opacity: 1;
}

#transfer-form .col-md-12 ul.nav li .icheckbox_square-blue:after {
  position: absolute;
  top: 50%;
  left: 67%;
  width: 100%;
  height: 100%;
  font-size: 14px;
  background: transparent;
  font-family: "FontAwesome";
  transform: translate(-50%, -50%);
  content: "\f00c";
  color: white;
  opacity: 0;
  pointer-events: none;
}

#transfer-form .col-md-12 .select-all-wrapper {
  margin-right: 10px;
}

#transfer-form .col-md-12 .select-all-wrapper .icheckbox_square-blue {
  margin-left: 10px;
  border: 1px solid #B5B5B5;
  border-radius: 5px;
  background: #B5B5B5;
}

#transfer-form .col-md-12 .select-all-wrapper .icheckbox_square-blue:hover {
  border-color: #F7893A;
}

#transfer-form .col-md-12 .select-all-wrapper .icheckbox_square-blue.checked {
  background: #f93;
  border-color: #fff;
}

#transfer-form .col-md-12 .select-all-wrapper .icheckbox_square-blue.checked::after {
  opacity: 1;
}

#transfer-form .col-md-12 .select-all-wrapper .icheckbox_square-blue:after {
  position: absolute;
  top: 50%;
  left: 67%;
  width: 100%;
  height: 100%;
  font-size: 14px;
  background: transparent;
  font-family: "FontAwesome";
  transform: translate(-50%, -50%);
  content: "\f00c";
  color: white;
  opacity: 0;
  pointer-events: none;
}

.iradio_square-blue {
  border-radius: 50px;
  border: 1px solid rgba(0,0,0,0.7);
  background: #fff;
}

.iradio_square-blue:hover {
  border: 1px solid #f93;
  background: none;
}

.iradio_square-blue.checked {
  background: #f93 !important;
  border: 1px solid #f93;
}

.iradio_square-blue.checked:after {
  font-family: "FontAwesome";
  content: "\f00c";
  color: #fff;
  top: 50%;
  left: 50%;
  position: absolute;
  font-size: 1.1rem;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

#transfer-form .col-md-12 ul.nav {
  border-bottom: 2px solid #f93;
}

#transfer-form .col-md-12 ul.nav li a {
  color: #000;
  padding: 20px 15px 20px 30px;
}

#transfer-form .col-md-12 ul.nav li a:hover,
#transfer-form .col-md-12 ul.nav li a:focus {
  background: #f93 !important;
  color: #fff !important;
}

#transfer-form .col-md-12 ul.nav li a:hover:after,
#transfer-form .col-md-12 ul.nav li a:focus:after {
  color: black;
}

#transfer-form .col-md-12 ul.nav li a:after {
  position: absolute;
  top: 50%;
  left: 5px;
  font-size: 18px;
  background: transparent;
  font-family: "FontAwesome";
  transform: translate(0%, -50%);
  content: "\f107";
  color: #f93;
  opacity: 1;
}

#transfer-form .col-md-12 ul.nav li[aria-expanded="true"] a:after {
  transform: translate(0%, -50%) rotate(180deg);
}

#transfer-form .col-md-12 #fair-select-button {
  margin-top: 25px;
  background: #f93;
  color: #fff;
  border: 1px solid #f93;
}

#transfer-form .col-md-12 #fair-select-button:hover {
  background: #F7893A;
  color: #fff !important;
  border: 1px solid #f93;
}

.bootstrap-datetimepicker-widget {
  background: #f5f5f5;
  border: none;
  box-shadow: 2px 3px 10px rgba(0,0,0,0.2);
}

.datepicker table thead tr th {
  color: #000;
}

.datepicker table thead tr th:hover {
  background: #fff !important;
}

.datepicker table tr td {
  text-shadow: none;
  color: #000;
}

.datepicker table tr td.active.active {
  background: #f93;
  color: #000;
  text-shadow: none;
}

.datepicker table tr td.active:hover.active {
  background: #F7893A;
}

.datepicker table tr td.active.disabled.disabled {
  background: #f93;
}

.datepicker table tr td.today {
  background: linear-gradient(135deg, #ff6b00 0%, #ffab42 95%);
  color: #000;
  text-shadow: none;
}

.datepicker table tr td.today:before {
  border-bottom: 7px solid white;
}

.datepicker table tr td.today:hover {
  color: #000 !important;
  background: #F7893A !important;
  background-color: #F7893A !important;
}

.datepicker table tr td.day:hover {
  color: #000 !important;
  background: #F7893A !important;
  background-color: #F7893A !important;
}

.timepicker .timepicker-picker a {
  color: #000;
}

.timepicker .timepicker-picker a:hover span {
  background: #fff !important;
}

.timepicker .timepicker-hours table tbody tr td:hover,
.timepicker .timepicker-minutes table tbody tr td:hover,
.timepicker .timepicker-seconds table tbody tr td:hover {
  background: #fff !important;
}

.bootstrap-datetimepicker-widget .picker-switch a {
  color: #000;
}

.bootstrap-datetimepicker-widget .picker-switch a:hover span {
  background: #fff !important;
}

.modal.ui-widget.ui-widget-content,
.ui-dialog.ui-widget.ui-widget-content {
  border: none !important;
  box-shadow: 3px 3px 8px rgba(0,0,0,0.15) !important;
  border-radius: 0 !important;
  background: #fff;
}

.modal.ui-widget.ui-widget-content .ui-tooltip.ui-corner-all,
.ui-dialog.ui-widget.ui-widget-content .ui-tooltip.ui-corner-all {
  left: calc(100% - 50px) !important;
}

.modal.ui-widget.ui-widget-content .sonata-ba-form-actions,
.ui-dialog.ui-widget.ui-widget-content .sonata-ba-form-actions {
  border-color: #000;
}

.modal .ui-dialog-titlebar,
.ui-dialog .ui-dialog-titlebar {
  background: transparent !important;
  border: none !important;
  padding-bottom: 0 !important;
}

.modal .ui-dialog-titlebar .ui-dialog-titlebar-close,
.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close {
  background: none !important;
  border: none !important;
}

.modal .ui-dialog-titlebar .ui-dialog-titlebar-close:active .ui-icon-closethick,
.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close:active .ui-icon-closethick {
  filter: invert(0) !important;
}

.modal .ui-dialog-titlebar .ui-dialog-titlebar-close .ui-icon-closethick,
.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close .ui-icon-closethick {
  filter: invert(1) !important;
}

.modal .modal-header,
.modal .ui-dialog-titlebar,
.ui-dialog .modal-header,
.ui-dialog .ui-dialog-titlebar {
  padding-left: 25px;
  border-bottom: 1px solid #000;
}

.modal .modal-header button.close,
.modal .ui-dialog-titlebar button.close,
.ui-dialog .modal-header button.close,
.ui-dialog .ui-dialog-titlebar button.close {
  color: #000;
  opacity: 0.6;
}

.modal .modal-header button.close:hover,
.modal .ui-dialog-titlebar button.close:hover,
.ui-dialog .modal-header button.close:hover,
.ui-dialog .ui-dialog-titlebar button.close:hover {
  opacity: 1;
}

.modal .modal-title,
.ui-dialog .modal-title {
  color: #000;
  font-weight: 400;
}

.modal .ui-dialog-content,
.ui-dialog .ui-dialog-content {
  padding-top: 10px !important;
}

.modal .modal-body,
.modal .ui-dialog-content,
.ui-dialog .modal-body,
.ui-dialog .ui-dialog-content {
  padding: 25px !important;
}

.modal .modal-body div.btn-group a,
.modal .ui-dialog-content div.btn-group a,
.ui-dialog .modal-body div.btn-group a,
.ui-dialog .ui-dialog-content div.btn-group a {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #000;
  font-weight: 400;
  border-radius: 5px !important;
  margin: 0;
  padding: 0;
}

.modal .modal-body div.btn-group a.btn-success,
.modal .ui-dialog-content div.btn-group a.btn-success,
.ui-dialog .modal-body div.btn-group a.btn-success,
.ui-dialog .ui-dialog-content div.btn-group a.btn-success {
  padding: 5px 10px;
}

.modal .modal-body div.btn-group a.btn-success i,
.modal .ui-dialog-content div.btn-group a.btn-success i,
.ui-dialog .modal-body div.btn-group a.btn-success i,
.ui-dialog .ui-dialog-content div.btn-group a.btn-success i {
  margin-left: 0;
  font-size: inherit;
}

.modal .modal-body div.btn-group a.active,
.modal .ui-dialog-content div.btn-group a.active,
.ui-dialog .modal-body div.btn-group a.active,
.ui-dialog .ui-dialog-content div.btn-group a.active {
  border: none;
  display: none;
}

.modal .modal-body div.btn-group a.active i,
.modal .ui-dialog-content div.btn-group a.active i,
.ui-dialog .modal-body div.btn-group a.active i,
.ui-dialog .ui-dialog-content div.btn-group a.active i {
  color: #f93;
}

.modal .modal-body div.btn-group a i,
.modal .ui-dialog-content div.btn-group a i,
.ui-dialog .modal-body div.btn-group a i,
.ui-dialog .ui-dialog-content div.btn-group a i {
  font-size: 2rem;
  color: #000;
  padding: 0;
  margin: 0 0 0 25px;
  display: initial;
  -webkit-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -moz-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -o-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -ms-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
}

.modal .modal-body div.btn-group a:hover,
.modal .ui-dialog-content div.btn-group a:hover,
.ui-dialog .modal-body div.btn-group a:hover,
.ui-dialog .ui-dialog-content div.btn-group a:hover {
  color: #F7893A;
}

.modal .modal-body div.btn-group a:hover i,
.modal .ui-dialog-content div.btn-group a:hover i,
.ui-dialog .modal-body div.btn-group a:hover i,
.ui-dialog .ui-dialog-content div.btn-group a:hover i {
  color: #F7893A;
}

.modal .modal-body .sonata-filter-form .form-group,
.modal .ui-dialog-content .sonata-filter-form .form-group,
.ui-dialog .modal-body .sonata-filter-form .form-group,
.ui-dialog .ui-dialog-content .sonata-filter-form .form-group {
  text-align: right;
  margin-right: -10px;
}

.modal .modal-body .form-group .btn-default,
.modal .ui-dialog-content .form-group .btn-default,
.ui-dialog .modal-body .form-group .btn-default,
.ui-dialog .ui-dialog-content .form-group .btn-default {
  background: #fff;
  color: #000;
  font-weight: 400;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px 9px;
  margin-top: 0;
}

.modal .modal-body .form-group button.btn,
.modal .ui-dialog-content .form-group button.btn,
.ui-dialog .modal-body .form-group button.btn,
.ui-dialog .ui-dialog-content .form-group button.btn {
  margin-top: 0;
  border: 1px solid #000;
  padding: 5px 9px;
}

.modal .modal-body .form-group a[data-toggle="advanced-filter"],
.modal .ui-dialog-content .form-group a[data-toggle="advanced-filter"],
.ui-dialog .modal-body .form-group a[data-toggle="advanced-filter"],
.ui-dialog .ui-dialog-content .form-group a[data-toggle="advanced-filter"] {
  color: #000;
}

.modal .modal-body .form-actions,
.modal .ui-dialog-content .form-actions,
.ui-dialog .modal-body .form-actions,
.ui-dialog .ui-dialog-content .form-actions {
  border: none;
  background: #fff;
  box-shadow: none;
  padding-left: 20px;
  border-top: 1px solid black;
  border-radius: 0;
}

.modal .modal-body .form-actions button[type="submit"],
.modal .ui-dialog-content .form-actions button[type="submit"],
.ui-dialog .modal-body .form-actions button[type="submit"],
.ui-dialog .ui-dialog-content .form-actions button[type="submit"] {
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  font-weight: 400;
  color: #000;
}

.modal .modal-body .form-actions button[type="submit"]:hover,
.modal .ui-dialog-content .form-actions button[type="submit"]:hover,
.ui-dialog .modal-body .form-actions button[type="submit"]:hover,
.ui-dialog .ui-dialog-content .form-actions button[type="submit"]:hover {
  color: #F7893A;
}

.modal .modal-body .box,
.modal .ui-dialog-content .box,
.ui-dialog .modal-body .box,
.ui-dialog .ui-dialog-content .box {
  background: #fff;
}

.modal .modal-body .box.box-primary .box-header,
.modal .ui-dialog-content .box.box-primary .box-header,
.ui-dialog .modal-body .box.box-primary .box-header,
.ui-dialog .ui-dialog-content .box.box-primary .box-header {
  background: transparent;
  color: #000 !important;
  padding-left: 0;
}

.modal .modal-body .box.box-primary .box-header .box-title,
.modal .ui-dialog-content .box.box-primary .box-header .box-title,
.ui-dialog .modal-body .box.box-primary .box-header .box-title,
.ui-dialog .ui-dialog-content .box.box-primary .box-header .box-title {
  font-weight: 500 !important;
}

.modal .modal-body .box.box-primary .box-body,
.modal .ui-dialog-content .box.box-primary .box-body,
.ui-dialog .modal-body .box.box-primary .box-body,
.ui-dialog .ui-dialog-content .box.box-primary .box-body {
  padding-left: 0;
}

.modal .modal-body .box .box-header .box-title,
.modal .ui-dialog-content .box .box-header .box-title,
.ui-dialog .modal-body .box .box-header .box-title,
.ui-dialog .ui-dialog-content .box .box-header .box-title {
  color: #000;
}

.modal .modal-body .box.box-success,
.modal .ui-dialog-content .box.box-success,
.ui-dialog .modal-body .box.box-success,
.ui-dialog .ui-dialog-content .box.box-success {
  border-top-color: #f93;
}

.modal .modal-body .box.box-success .box-body,
.modal .ui-dialog-content .box.box-success .box-body,
.ui-dialog .modal-body .box.box-success .box-body,
.ui-dialog .ui-dialog-content .box.box-success .box-body {
  display: flex;
  margin: 20px 0;
}

.modal .modal-body .box.box-success .box-body div,
.modal .ui-dialog-content .box.box-success .box-body div,
.ui-dialog .modal-body .box.box-success .box-body div,
.ui-dialog .ui-dialog-content .box.box-success .box-body div {
  margin: auto;
}

.modal .modal-body .btn.btn-app,
.modal .ui-dialog-content .btn.btn-app,
.ui-dialog .modal-body .btn.btn-app,
.ui-dialog .ui-dialog-content .btn.btn-app {
  background: #fff;
  color: #000;
}

.modal .modal-body textarea.form-control,
.modal .ui-dialog-content textarea.form-control,
.ui-dialog .modal-body textarea.form-control,
.ui-dialog .ui-dialog-content textarea.form-control {
  background: #f5f5f5;
  color: #000;
}

.modal .modal-body textarea.form-control:focus,
.modal .modal-body textarea.form-control:active,
.modal .ui-dialog-content textarea.form-control:focus,
.modal .ui-dialog-content textarea.form-control:active,
.ui-dialog .modal-body textarea.form-control:focus,
.ui-dialog .modal-body textarea.form-control:active,
.ui-dialog .ui-dialog-content textarea.form-control:focus,
.ui-dialog .ui-dialog-content textarea.form-control:active {
  border-color: #000;
}

.modal .modal-body .nav-tabs-custom .tab-content,
.modal .ui-dialog-content .nav-tabs-custom .tab-content,
.ui-dialog .modal-body .nav-tabs-custom .tab-content,
.ui-dialog .ui-dialog-content .nav-tabs-custom .tab-content {
  padding-left: 0;
}

.modal .modal-body .nav-tabs-custom .tab-content .list-unstyled label,
.modal .ui-dialog-content .nav-tabs-custom .tab-content .list-unstyled label,
.ui-dialog .modal-body .nav-tabs-custom .tab-content .list-unstyled label,
.ui-dialog .ui-dialog-content .nav-tabs-custom .tab-content .list-unstyled label {
  color: #000 !important;
}

.modal .modal-content,
.ui-dialog .modal-content {
  background: #fff;
}

.modal .modal-content form .box-header,
.ui-dialog .modal-content form .box-header {
  text-align: left;
}

.modal ul.nav>li.dropdown a.dropdown-toggle,
.ui-dialog ul.nav>li.dropdown a.dropdown-toggle {
  float: right;
  display: -webkit-inline-box;
  background-color: transparent;
}

.modal ul.nav>li.dropdown.open i,
.ui-dialog ul.nav>li.dropdown.open i {
  color: #000;
}

.modal ul.nav>li.dropdown,
.ui-dialog ul.nav>li.dropdown {
  margin-top: -1px;
}

.modal ul.nav>li a,
.ui-dialog ul.nav>li a {
  color: #000;
  font-size: 0;
  font-weight: 400;
}

.modal ul.nav>li a i,
.ui-dialog ul.nav>li a i {
  font-size: 2.4rem;
}

.modal ul.nav>li>a,
.ui-dialog ul.nav>li>a {
  border: none;
  border-radius: 5px;
  display: initial;
  padding: 0;
}

.modal ul.nav>li>a i,
.ui-dialog ul.nav>li>a i {
  line-height: inherit;
}

.modal ul.nav>li>a i.fa-plus-circle:before,
.ui-dialog ul.nav>li>a i.fa-plus-circle:before {
  font-family: "FontAwesome";
  content: "\f067";
  color: #000;
}

.modal ul.nav>li .dropdown-menu,
.ui-dialog ul.nav>li .dropdown-menu {
  border: 1px solid black;
  padding: 5px 7px;
  clear: both;
  background: #f5f5f5;
}

.modal ul.nav>li .dropdown-menu.ui-sortable,
.ui-dialog ul.nav>li .dropdown-menu.ui-sortable {
  max-height: 50vh;
  overflow-y: scroll;
}

.modal ul.nav>li .dropdown-menu.ui-sortable li:last-child,
.ui-dialog ul.nav>li .dropdown-menu.ui-sortable li:last-child {
  margin: 15px 0 10px 0;
}

.modal ul.nav>li .dropdown-menu.ui-sortable li:last-child a,
.ui-dialog ul.nav>li .dropdown-menu.ui-sortable li:last-child a {
  background: #f93;
  border: none;
  border-radius: 5px;
  color: #000;
  font-weight: 500;
}

.modal ul.nav>li .dropdown-menu a,
.ui-dialog ul.nav>li .dropdown-menu a {
  font-size: 14px;
  padding: 3px 7px 4px;
  border-radius: 5px;
}

.modal ul.nav>li .dropdown-menu a:hover,
.ui-dialog ul.nav>li .dropdown-menu a:hover {
  background: #F7893A;
  color: #000 !important;
}

.modal ul.nav>li .dropdown-menu a:focus,
.ui-dialog ul.nav>li .dropdown-menu a:focus {
  background: transparent !important;
}

.modal ul.nav>li .dropdown-menu a i,
.ui-dialog ul.nav>li .dropdown-menu a i {
  font-size: initial;
}

.modal ul.nav>li:hover>a,
.ui-dialog ul.nav>li:hover>a {
  color: #F7893A !important;
}

.modal ul.nav>li:hover>a>i,
.ui-dialog ul.nav>li:hover>a>i {
  color: #F7893A !important;
}

.modal ul.nav>li:hover>a .badge,
.ui-dialog ul.nav>li:hover>a .badge {
  color: #F7893A;
}

.modal ul.nav>li:hover>a .badge:after,
.modal ul.nav>li:hover>a .badge:before,
.ui-dialog ul.nav>li:hover>a .badge:after,
.ui-dialog ul.nav>li:hover>a .badge:before {
  color: #F7893A !important;
}

.modal ul.nav>li .badge,
.ui-dialog ul.nav>li .badge {
  position: relative;
  background: transparent;
  color: #f93;
  border: none;
  padding: 5px 8px;
  border-radius: 15px;
  display: table-cell;
  -webkit-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -moz-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -o-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -ms-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
}

.modal ul.nav>li .badge:after,
.ui-dialog ul.nav>li .badge:after {
  position: absolute;
  top: 48%;
  left: 10%;
  transform: translate(0, -50%);
  color: #000;
  content: '(';
}

.modal ul.nav>li .badge:before,
.ui-dialog ul.nav>li .badge:before {
  position: absolute;
  top: 48%;
  right: 10%;
  transform: translate(0, -50%);
  color: #000;
  content: ')';
}

.modal ul.nav>li b.caret,
.ui-dialog ul.nav>li b.caret {
  display: none;
}

.modal ul.nav.nav-tabs,
.ui-dialog ul.nav.nav-tabs {
  padding-left: 20px;
  border: none;
}

.modal ul.nav.nav-tabs li,
.ui-dialog ul.nav.nav-tabs li {
  border: none;
  margin: 0;
}

.modal ul.nav.nav-tabs li:first-child a,
.ui-dialog ul.nav.nav-tabs li:first-child a {
  border-radius: 5px 0 0 5px;
}

.modal ul.nav.nav-tabs li:last-child a,
.ui-dialog ul.nav.nav-tabs li:last-child a {
  border-radius: 0px 5px 5px 0px;
}

.modal ul.nav.nav-tabs li:only-child a,
.ui-dialog ul.nav.nav-tabs li:only-child a {
  border-radius: 5px;
}

.modal ul.nav.nav-tabs li.active a,
.ui-dialog ul.nav.nav-tabs li.active a {
  background: #868686;
  color: #000;
}

.modal ul.nav.nav-tabs li a,
.ui-dialog ul.nav.nav-tabs li a {
  border: none;
  color: #000;
  background: #f5f5f5;
  font-size: 1.5rem;
  padding: 7px 12px;
}

.modal ul.nav.nav-tabs li a i.has-errors,
.ui-dialog ul.nav.nav-tabs li a i.has-errors {
  font-size: inherit;
}

.modal ul.nav.nav-tabs li a:hover,
.ui-dialog ul.nav.nav-tabs li a:hover {
  color: #fff !important;
  background: #868686;
}

.modal ul.nav .tab-content,
.ui-dialog ul.nav .tab-content {
  padding-left: 0;
  padding-right: 0;
}

.modal ul.nav .tab-content .box-header,
.ui-dialog ul.nav .tab-content .box-header {
  padding-left: 0;
}

.modal ul.nav .tab-content .box-body,
.ui-dialog ul.nav .tab-content .box-body {
  padding-left: 0;
  padding-right: 0;
}

.modal ul,
.ui-dialog ul {
  position: relative;
}

.modal ul.sonata-tree,
.ui-dialog ul.sonata-tree {
  overflow: visible;
}

.modal ul.sonata-tree li div .fa-caret-right,
.ui-dialog ul.sonata-tree li div .fa-caret-right {
  color: #000;
  font-size: 24px;
  left: -25px;
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  -ms-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

.modal ul.sonata-tree li div .fa-caret-right:before,
.ui-dialog ul.sonata-tree li div .fa-caret-right:before {
  content: "\f105" !important;
}

.modal ul.sonata-tree .sonata-tree__item,
.ui-dialog ul.sonata-tree .sonata-tree__item {
  border-radius: 5px;
  padding: 5px 9px;
  background: #f5f5f5;
  border: 1px solid #000;
}

.modal ul.sonata-tree .sonata-tree__item.is-active,
.ui-dialog ul.sonata-tree .sonata-tree__item.is-active {
  background: #f93;
  color: #000;
  border: 1px solid transparent;
}

.modal ul.sonata-tree .sonata-tree__item.is-active:hover,
.ui-dialog ul.sonata-tree .sonata-tree__item.is-active:hover {
  background: #F7893A;
  color: #000;
}

.modal ul.sonata-tree .sonata-tree__item.is-active a:hover,
.ui-dialog ul.sonata-tree .sonata-tree__item.is-active a:hover {
  color: #000 !important;
}

.modal ul.sonata-tree .sonata-tree__item:hover,
.ui-dialog ul.sonata-tree .sonata-tree__item:hover {
  background: rgba(0,0,0,0.05);
}

.modal ul.sonata-tree .sonata-tree__item a,
.ui-dialog ul.sonata-tree .sonata-tree__item a {
  color: #000;
  font-weight: 400;
}

.modal ul.sonata-tree .sonata-tree__item:after,
.ui-dialog ul.sonata-tree .sonata-tree__item:after {
  display: none;
}

.modal ul.sonata-tree .sonata-tree__item:before,
.ui-dialog ul.sonata-tree .sonata-tree__item:before {
  display: none;
}

.modal .form-group,
.modal form,
.ui-dialog .form-group,
.ui-dialog form {
  margin-bottom: 20px;
}

.modal .form-group input[type="text"],
.modal .form-group input[type="url"],
.modal .form-group input[type="email"],
.modal .form-group input[type="password"],
.modal form input[type="text"],
.modal form input[type="url"],
.modal form input[type="email"],
.modal form input[type="password"],
.ui-dialog .form-group input[type="text"],
.ui-dialog .form-group input[type="url"],
.ui-dialog .form-group input[type="email"],
.ui-dialog .form-group input[type="password"],
.ui-dialog form input[type="text"],
.ui-dialog form input[type="url"],
.ui-dialog form input[type="email"],
.ui-dialog form input[type="password"] {
  background: #f5f5f5;
  border: none;
  border-bottom: 1px solid transparent;
  color: #000;
}

.modal .form-group input[type="text"]:focus,
.modal .form-group input[type="url"]:focus,
.modal .form-group input[type="email"]:focus,
.modal .form-group input[type="password"]:focus,
.modal form input[type="text"]:focus,
.modal form input[type="url"]:focus,
.modal form input[type="email"]:focus,
.modal form input[type="password"]:focus,
.ui-dialog .form-group input[type="text"]:focus,
.ui-dialog .form-group input[type="url"]:focus,
.ui-dialog .form-group input[type="email"]:focus,
.ui-dialog .form-group input[type="password"]:focus,
.ui-dialog form input[type="text"]:focus,
.ui-dialog form input[type="url"]:focus,
.ui-dialog form input[type="email"]:focus,
.ui-dialog form input[type="password"]:focus {
  border-color: #f93;
}

.modal .form-group input[type="number"],
.modal form input[type="number"],
.ui-dialog .form-group input[type="number"],
.ui-dialog form input[type="number"] {
  background: #f5f5f5;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid transparent;
  color: #000;
}

.modal .form-group .btn-default,
.modal form .btn-default,
.ui-dialog .form-group .btn-default,
.ui-dialog form .btn-default {
  background: #fff;
  color: #000;
  font-weight: 400;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px 9px;
  margin-top: 0;
}

.modal .form-group .btn-group,
.modal .form-group .form-actions,
.modal form .btn-group,
.modal form .form-actions,
.ui-dialog .form-group .btn-group,
.ui-dialog .form-group .form-actions,
.ui-dialog form .btn-group,
.ui-dialog form .form-actions {
  padding-left: 0px !important;
}

.modal .form-group .btn-group a.btn,
.modal .form-group .form-actions a.btn,
.modal form .btn-group a.btn,
.modal form .form-actions a.btn,
.ui-dialog .form-group .btn-group a.btn,
.ui-dialog .form-group .form-actions a.btn,
.ui-dialog form .btn-group a.btn,
.ui-dialog form .form-actions a.btn {
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  font-weight: 400;
  border-radius: 5px !important;
  margin-right: 4px;
}

.modal .form-group .btn-group a.btn:first-child:not(:last-child):not(.dropdown-toggle),
.modal .form-group .form-actions a.btn:first-child:not(:last-child):not(.dropdown-toggle),
.modal form .btn-group a.btn:first-child:not(:last-child):not(.dropdown-toggle),
.modal form .form-actions a.btn:first-child:not(:last-child):not(.dropdown-toggle),
.ui-dialog .form-group .btn-group a.btn:first-child:not(:last-child):not(.dropdown-toggle),
.ui-dialog .form-group .form-actions a.btn:first-child:not(:last-child):not(.dropdown-toggle),
.ui-dialog form .btn-group a.btn:first-child:not(:last-child):not(.dropdown-toggle),
.ui-dialog form .form-actions a.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 5px;
  margin-right: 4px;
}

.modal .form-group .btn-group a.btn.btn-danger,
.modal .form-group .form-actions a.btn.btn-danger,
.modal form .btn-group a.btn.btn-danger,
.modal form .form-actions a.btn.btn-danger,
.ui-dialog .form-group .btn-group a.btn.btn-danger,
.ui-dialog .form-group .form-actions a.btn.btn-danger,
.ui-dialog form .btn-group a.btn.btn-danger,
.ui-dialog form .form-actions a.btn.btn-danger {
  color: red;
}

.modal .form-group .btn-group a.btn.btn-danger:hover,
.modal .form-group .form-actions a.btn.btn-danger:hover,
.modal form .btn-group a.btn.btn-danger:hover,
.modal form .form-actions a.btn.btn-danger:hover,
.ui-dialog .form-group .btn-group a.btn.btn-danger:hover,
.ui-dialog .form-group .form-actions a.btn.btn-danger:hover,
.ui-dialog form .btn-group a.btn.btn-danger:hover,
.ui-dialog form .form-actions a.btn.btn-danger:hover {
  color: red !important;
  border-color: red;
}

.modal .form-group .btn-group a.btn.btn-danger:hover i,
.modal .form-group .form-actions a.btn.btn-danger:hover i,
.modal form .btn-group a.btn.btn-danger:hover i,
.modal form .form-actions a.btn.btn-danger:hover i,
.ui-dialog .form-group .btn-group a.btn.btn-danger:hover i,
.ui-dialog .form-group .form-actions a.btn.btn-danger:hover i,
.ui-dialog form .btn-group a.btn.btn-danger:hover i,
.ui-dialog form .form-actions a.btn.btn-danger:hover i {
  color: red !important;
}

.modal .form-group button.btn,
.modal .form-group button.btn.btn-primary,
.modal form button.btn,
.modal form button.btn.btn-primary,
.ui-dialog .form-group button.btn,
.ui-dialog .form-group button.btn.btn-primary,
.ui-dialog form button.btn,
.ui-dialog form button.btn.btn-primary {
  margin-top: 0;
  padding: 5px 9px;
}

.modal .form-group div.select2-container .select2-choice,
.modal form div.select2-container .select2-choice,
.ui-dialog .form-group div.select2-container .select2-choice,
.ui-dialog form div.select2-container .select2-choice {
  color: #000;
  font-weight: 400;
  border: 1px solid #000;
  border-radius: 5px !important;
  box-shadow: none;
  background: #f5f5f5;
}

.modal .form-group div.select2-container.select2-dropdown-open .select2-choice,
.modal form div.select2-container.select2-dropdown-open .select2-choice,
.ui-dialog .form-group div.select2-container.select2-dropdown-open .select2-choice,
.ui-dialog form div.select2-container.select2-dropdown-open .select2-choice {
  border-radius: 5px 5px 0 0 !important;
  border-bottom: 1px solid #000;
  background: #f5f5f5;
}

.modal .form-group a.sonata-toggle-filter i,
.modal form a.sonata-toggle-filter i,
.ui-dialog .form-group a.sonata-toggle-filter i,
.ui-dialog form a.sonata-toggle-filter i {
  color: #000;
}

.modal .form-group a:hover i,
.modal form a:hover i,
.ui-dialog .form-group a:hover i,
.ui-dialog form a:hover i {
  color: #F7893A;
}

.modal .form-group a[data-toggle="advanced-filter"],
.modal form a[data-toggle="advanced-filter"],
.ui-dialog .form-group a[data-toggle="advanced-filter"],
.ui-dialog form a[data-toggle="advanced-filter"] {
  color: #000;
  margin-top: 0;
}

.modal .form-group .field-short-description,
.modal form .field-short-description,
.ui-dialog .form-group .field-short-description,
.ui-dialog form .field-short-description {
  border: 1px solid black;
}

.modal .form-group .field-short-description a,
.modal form .field-short-description a,
.ui-dialog .form-group .field-short-description a,
.ui-dialog form .field-short-description a {
  color: #fff;
  font-weight: 400;
}

.modal .navbar .container-fluid,
.ui-dialog .navbar .container-fluid {
  padding-right: 0;
}

.modal .nav-tabs-custom,
.ui-dialog .nav-tabs-custom {
  background: #fff;
}

.modal .nav-tabs-custom>.tab-content,
.ui-dialog .nav-tabs-custom>.tab-content {
  background: #fff;
}

.modal .modal-footer button.btn,
.ui-dialog .modal-footer button.btn {
  margin-top: 0 !important;
}

.modal .modal-footer a.btn.btn-primary,
.ui-dialog .modal-footer a.btn.btn-primary {
  margin-top: 0 !important;
}

.pagination li {
  border: none;
  background: transparent;
}

.pagination li:first-child a {
  border-radius: 5px 0 0 5px;
}

.pagination li:last-child a {
  border-radius: 0px 5px 5px 0;
}

.pagination li a {
  border: none;
  background: #f5f5f5;
  color: #000;
  font-weight: 500;
}

.pagination li.active a {
  background: #f93;
}

.pagination li.active a:hover {
  cursor: pointer;
}

.pagination li.active a:hover,
.pagination li.active a:focus {
  background: #F7893A;
  color: #fff !important;
}

.popover {
  box-shadow: 0px 0px 10px #0003;
  border: none;
}

.popover .popover-title {
  background: #fff;
  font-weight: 500;
  border: none;
  border-bottom: 2px solid #f93;
}

.popover button.btn {
  font-weight: 400;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.popover button.btn:hover {
  color: #000 !important;
  background: #f5f5f5;
}

.popover button.btn.editable-submit {
  color: #000 !important;
  background: #f93;
  border-color: transparent;
}

.popover button.btn.editable-submit:hover {
  background: #F7893A;
}

.popover .editable-input {
  background: #fff;
  border-radius: 5px;
  border: none;
  font-weight: 400;
  margin-top: 10px;
}

.popover .editable-input select {
  border: 1px solid #000;
  border-radius: 5px;
}

.popover .editable-input select option {
  border: none;
}

.sonata-search-result-show {
  text-align: left;
  color: #000;
  margin-bottom: 15px;
}

.sonata-search-result-show .box {
  box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
}

.sonata-search-result-show .box .box-header {
  padding-left: 15px;
}

.sonata-search-result-show .box .box-header i {
  vertical-align: middle;
}

.sonata-search-result-show .box .box-header .box-title {
  font-size: 14px;
}

.sonata-search-result-show .box .box-header .box-tools a {
  color: #000;
  font-size: 18px;
}

.sonata-search-result-show .box .box-header .box-tools a:hover {
  background: transparent;
}

.sonata-search-result-show .box .box-header .box-tools .badge {
  position: relative;
  background: transparent;
  color: #f93;
  font-size: 18px;
  line-height: initial;
  margin-top: -2px;
}

.sonata-search-result-show .box .box-header .box-tools .badge:before {
  position: absolute;
  content: "(";
  color: #000;
  top: 2px;
  left: 0;
}

.sonata-search-result-show .box .box-header .box-tools .badge:after {
  position: absolute;
  content: ")";
  color: #000;
  top: 2px;
  right: 0;
}

.sonata-search-result-show .box .box-body a {
  border-left: 0;
}

.sonata-search-result-show .sonata-search-result-list li a {
  color: #000;
}

.sonata-search-result-show .sonata-search-result-list li>a:hover {
  background: #fff;
}

.select2-container-multi .select2-choices li.select2-search-choice {
  color: #fff;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  padding: 6px 8px 6px 18px;
  background: #000;
  margin-bottom: 7px;
}

.select2-container-multi .select2-choices li.select2-search-choice a.select2-search-choice-close {
  transition: all 0s;
  background: none;
}

.select2-container-multi .select2-choices li.select2-search-choice a:after {
  font-family: "FontAwesome";
  content: "\f00d";
  color: #fff;
  top: 50%;
  left: 50%;
  position: absolute;
  font-size: 1.1rem;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0.7;
}

.select2-container-multi .select2-choices li.select2-search-choice a:hover:after {
  opacity: 1;
  color: #F7893A;
}

div.wrapper div.content-wrapper .content .nav-tabs {
  border: none;
}

div.wrapper div.content-wrapper .content .nav-tabs li {
  border: none;
  margin: 0;
}

div.wrapper div.content-wrapper .content .nav-tabs li:first-child a {
  border-radius: 5px 0 0 5px;
}

div.wrapper div.content-wrapper .content .nav-tabs li:last-child a {
  border-radius: 0px 5px 5px 0px;
}

div.wrapper div.content-wrapper .content .nav-tabs li.active a {
  background: #868686;
  color: #000;
}

div.wrapper div.content-wrapper .content .nav-tabs li:only-child a {
  border-radius: 5px;
}

div.wrapper div.content-wrapper .content .nav-tabs li a {
  border: none;
  color: #000;
  background: #f5f5f5;
}

div.wrapper div.content-wrapper .content .nav-tabs li a i.has-errors {
  font-size: inherit;
}

div.wrapper div.content-wrapper .content .nav-tabs li a:hover {
  color: #fff !important;
  background: #868686;
}

.wrapper header.main-header {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  height: 80px;
}

.wrapper header.main-header .logo {
  height: 80px;
  line-height: 40px;
  padding: 15px 15px;
  border: none;
  background: #fff;
}

.wrapper header.main-header .logo:hover {
  background: #f5f5f5;
}

.wrapper header.main-header .logo span {
  font-weight: 400;
  width: 100px;
  text-align: left;
  letter-spacing: 0.5px;
  color: #000;
}

.wrapper header.main-header .logo img {
  padding-bottom: 0;
  margin-right: 5px;
}

.wrapper header.main-header .navbar {
  background: #fff !important;
}

.wrapper header.main-header .navbar .sidebar-toggle {
  border: none;
  color: #000;
  padding: 0px 15px 0 15px;
  margin-top: 40px;
  font-size: 2.2rem;
  transform: translate(0, -50%);
  display: none;
}

.wrapper header.main-header .navbar .sidebar-toggle:hover {
  color: #F7893A;
  background: none;
}

.wrapper header.main-header .navbar .navbar-custom-menu {
  max-height: 80px;
}

.wrapper header.main-header .navbar .nav {
  font-size: 1.8rem;
  color: #000;
  font-weight: 400;
  margin-top: -5px;
}

.wrapper header.main-header .navbar .nav li {
  margin: 40px 80px 0 0;
  transform: translate(0, -50%);
}

.wrapper header.main-header .navbar .nav li a {
  border: none !important;
  padding-right: 0;
  background: none;
  color: #000;
}

.wrapper header.main-header .navbar .nav li a p:nth-child(1):before {
  position: relative;
  font-family: "FontAwesome";
  content: "\f007";
  color: #000;
  left: -4px;
  font-size: 1.8rem;
  background: transparent;
  padding: 7px 10px;
}

.wrapper header.main-header .navbar .nav li a i {
  display: none;
}

.wrapper header.main-header .navbar .nav li a:hover {
  background: none;
}

.wrapper header.main-header .navbar .nav li a:hover p:before {
  color: #F7893A;
}

.wrapper header.main-header .navbar .nav.breadcrumb li {
  margin-top: 50%;
  transform: translate(0, -50%);
  display: contents;
  height: 80px;
}

.wrapper header.main-header .navbar .nav.breadcrumb li span {
  min-height: 45px;
}

.wrapper header.main-header .navbar .nav .user-menu .dropdown-toggle {
  background: none;
}

.wrapper header.main-header .navbar .nav .user-menu ul.dropdown-menu {
  border: none;
  box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
  padding: 5px 10px 5px 20px;
  margin: 10px 10px 0 0;
  background: #f5f5f5;
}

.wrapper header.main-header .navbar .nav .user-menu ul.dropdown-menu li {
  margin-top: 25px;
}

.wrapper header.main-header .navbar .nav .user-menu ul.dropdown-menu li a {
  color: #000;
  font-weight: 400;
}

.wrapper header.main-header .navbar .nav .user-menu.open a p {
  color: #000;
}

input[type="file"] {
  color: #000;
  padding-top: 10px;
  padding: 10px 0 0 30px;
  height: inherit;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

input[readonly="readonly"] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  pointer-events: none;
}

input[type="file"]::before {
  content: 'Datei auswählen';
  color: #000;
  display: inline-block;
  background: transparent;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 3px 10px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: none;
  font-weight: 400;
  transform: translate(-30px, -5px);
}

input[type="file"]:hover::before {
  border-color: #000;
  color: #F7893A;
}

input[type="file"]:active {
  outline: 0;
}

input[type="file"]:active::before {
  border-color: #000;
  color: #f93;
}

div.form-group textarea {
  background-color: #f5f5f5;
  border: none;
  color: #000;
  border-bottom: 1px solid #000;
  max-width: 100% !important;
  padding: 10px 12px;
}

div.form-group textarea:focus {
  border-color: #f93;
}

.col-xs-12 textarea,
.col-sm-12 textarea,
.col-md-12 textarea,
.col-lg-12 textarea,
.col-xlg-12 textarea {
  width: 50%;
}

.col-xs-12 table tr td textarea,
.col-sm-12 table tr td textarea,
.col-md-12 table tr td textarea,
.col-lg-12 table tr td textarea,
.col-xlg-12 table tr td textarea {
  width: 100%;
}

.col-sm-6 textarea,
.col-md-6 textarea,
.col-lg-6 textarea,
.col-xlg-6 textarea {
  width: 100%;
}

.form-control.disabled {
  cursor: not-allowed;
  background: #aaa;
}

.form-control.disabled:focus {
  border-bottom: none !important;
}

.no-resize {
  resize: none;
}

body.sonata-bc div.wrapper {
  background: #ffffff !important;
}

body.login-page {
  background: #ffffff;
}

body.login-page header.main-header {
  box-shadow: none;
}

body.login-page .login-box {
  padding: 40px 60px 50px 60px;
  box-sizing: content-box;
  box-shadow: 0px 0px 22px rgba(0,0,0,0.2);
  background: #fff;
}

body.login-page .login-box a {
  color: #000;
}

body.login-page .login-box .login-box-body {
  background: #fff;
}

body.login-page .login-box .login-box-body form {
  margin-bottom: 10px;
}

body.login-page .login-logo {
  line-height: 0.9;
  padding-left: 10px;
  padding-top: 10px;
  font-weight: 400;
  font-size: 3.2rem;
  text-align: left;
  letter-spacing: 0.5px;
  color: #000;
  margin-bottom: 50px;
  margin-left: 50%;
  transform: translate(-50%, 0);
}

body.login-page .login-logo a>div {
  float: left;
}

body.login-page .login-logo a span {
  padding: 10px 0 0 10px;
  display: flex;
}

body.login-page .login-box-msg {
  font-size: 1.8rem;
  font-weight: 400;
  color: #000;
}

body.login-page .form-group input {
  background: #fff;
  border: none;
  border-bottom: 1px solid #000;
  color: #000;
  font-weight: 400;
}

body.login-page .form-group input:focus {
  border-color: #f93;
}

body.login-page .form-group .glyphicon {
  color: #000;
}

body.login-page .form-group:nth-child(3) {
  margin-bottom: 40px;
}

.wrapper aside.main-sidebar {
  padding-top: 80px;
  background: #fff;
  box-shadow: 0 3px 10px rgba(0,0,0,0.16);
  width: 200px;
  display: table;
}

.wrapper aside.main-sidebar form {
  border-radius: 0;
  border: none;
}

.wrapper aside.main-sidebar form .input-group input[type="text"],
.wrapper aside.main-sidebar form .input-group .btn {
  background: #f5f5f5;
  border-bottom: 1px solid transparent;
  color: #000;
  padding-left: 5px;
}

.wrapper aside.main-sidebar ul li.treeview {
  font-size: 1.4rem;
}

.wrapper aside.main-sidebar ul li.treeview a {
  color: #000;
  text-align: center;
  padding: 20px 5px 20px 5px;
  border-left: 0;
}

.wrapper aside.main-sidebar ul li.treeview a i {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 2.1rem;
  margin-bottom: 8px;
}

.wrapper aside.main-sidebar ul li.treeview a span:nth-child(2) {
  display: block;
  margin-top: 0px;
  opacity: 1;
  -webkit-transition: opacity .2s ease-in-out, margin-top .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, margin-top .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, margin-top .2s ease-in-out;
  -ms-transition: opacity .2s ease-in-out, margin-top .2s ease-in-out;
  transition: opacity .2s ease-in-out, margin-top .2s ease-in-out;
}

.wrapper aside.main-sidebar ul li.treeview a .pull-right-container {
  display: none;
}

.wrapper aside.main-sidebar ul li.treeview:hover>a {
  background: #f5f5f5;
  color: #000;
}

.wrapper aside.main-sidebar ul li.treeview ul.treeview-menu {
  margin: 0;
  padding-left: 0;
  background: #fff;
  position: relative;
  overflow: hidden;
}

.wrapper aside.main-sidebar ul li.treeview ul.treeview-menu a {
  font-weight: 400;
  padding: 15px 5px 15px 5px;
}

.wrapper aside.main-sidebar ul li.treeview ul.treeview-menu:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0%;
  background: #fff;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.2);
}

.wrapper aside.main-sidebar ul li.treeview ul.treeview-menu:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: -3px;
  left: 0%;
  background: transparent;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.6);
  z-index: 1;
}

.wrapper aside.main-sidebar ul li.treeview ul.treeview-menu i {
  display: none;
}

.wrapper aside.main-sidebar ul li.treeview.active>a {
  background: #f5f5f5;
  color: #000;
}

.wrapper aside.main-sidebar ul li.treeview.active ul.treeview-menu li:hover a {
  color: #F7893A !important;
}

.wrapper aside.main-sidebar ul li.treeview.active ul.treeview-menu li a {
  background: #fff;
}

.wrapper aside.main-sidebar ul li.treeview.active ul.treeview-menu li.active a {
  color: #f93 !important;
}

.wrapper aside.main-sidebar p {
  border-top: 0 !important;
}

body.sonata-bc div.wrapper table.table,
body.sonata-bc .modal table.table {
  border: none;
}

body.sonata-bc div.wrapper table.table thead,
body.sonata-bc .modal table.table thead {
  background: #f5f5f5;
  color: #000;
}

body.sonata-bc div.wrapper table.table thead th,
body.sonata-bc .modal table.table thead th {
  border: none;
  position: relative;
  padding-left: 11px;
  font-weight: 400;
  vertical-align: middle;
}

body.sonata-bc div.wrapper table.table thead th:after,
body.sonata-bc .modal table.table thead th:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 65%;
  right: 0px;
  top: 17.5%;
  background: #fff;
}

body.sonata-bc div.wrapper table.table thead th:last-child:after,
body.sonata-bc .modal table.table thead th:last-child:after {
  width: 0;
  background: transparent;
}

body.sonata-bc div.wrapper table.table thead th a,
body.sonata-bc .modal table.table thead th a {
  color: #000;
  white-space: nowrap;
}

body.sonata-bc div.wrapper table.table thead th a:after,
body.sonata-bc div.wrapper table.table thead th a:before,
body.sonata-bc .modal table.table thead th a:after,
body.sonata-bc .modal table.table thead th a:before {
  right: -20px;
  color: #000;
  font-family: "FontAwesome";
  font-size: 14px;
  content: "\f106";
  top: 50%;
  margin-top: 0;
  right: -15px;
  position: unset;
  display: inherit;
  line-height: 0.8;
  transform: translate(0, -50%);
  opacity: 0.5;
}

body.sonata-bc div.wrapper table.table thead th a:before,
body.sonata-bc .modal table.table thead th a:before {
  content: "\f107";
  right: 0;
  position: absolute;
  transform: translate(0, -10%);
}

body.sonata-bc div.wrapper table.table thead th a:hover:after,
body.sonata-bc div.wrapper table.table thead th a:hover:before,
body.sonata-bc .modal table.table thead th a:hover:after,
body.sonata-bc .modal table.table thead th a:hover:before {
  color: #000;
  right: -20px;
  top: 50%;
  margin-top: 0;
  right: -15px;
  font-size: 14px;
  position: unset;
  display: inherit;
}

body.sonata-bc div.wrapper table.table thead th a:hover:before,
body.sonata-bc .modal table.table thead th a:hover:before {
  right: 0;
  position: absolute;
  transform: translate(0, -10%);
}

body.sonata-bc div.wrapper table.table thead th a:hover:after,
body.sonata-bc .modal table.table thead th a:hover:after {
  color: #F7893A;
  opacity: 1;
}

body.sonata-bc div.wrapper table.table thead th.sonata-ba-list-field-header-order-desc.sonata-ba-list-field-order-active a:before,
body.sonata-bc .modal table.table thead th.sonata-ba-list-field-header-order-desc.sonata-ba-list-field-order-active a:before {
  font-size: 14px;
  color: #f93;
  opacity: 1;
}

body.sonata-bc div.wrapper table.table thead th.sonata-ba-list-field-header-order-desc.sonata-ba-list-field-order-active a:hover:before,
body.sonata-bc .modal table.table thead th.sonata-ba-list-field-header-order-desc.sonata-ba-list-field-order-active a:hover:before {
  font-size: 14px;
  color: #000;
  opacity: 0.5;
}

body.sonata-bc div.wrapper table.table thead th.sonata-ba-list-field-header-order-desc.sonata-ba-list-field-order-active a:hover:after,
body.sonata-bc .modal table.table thead th.sonata-ba-list-field-header-order-desc.sonata-ba-list-field-order-active a:hover:after {
  font-size: 14px;
  color: #F7893A;
  opacity: 1;
}

body.sonata-bc div.wrapper table.table thead th.sonata-ba-list-field-header-order-asc.sonata-ba-list-field-order-active a:after,
body.sonata-bc .modal table.table thead th.sonata-ba-list-field-header-order-asc.sonata-ba-list-field-order-active a:after {
  font-size: 14px;
  color: #f93;
  opacity: 1;
}

body.sonata-bc div.wrapper table.table thead th.sonata-ba-list-field-header-order-asc.sonata-ba-list-field-order-active a:hover:after,
body.sonata-bc .modal table.table thead th.sonata-ba-list-field-header-order-asc.sonata-ba-list-field-order-active a:hover:after {
  font-size: 14px;
  color: #fff;
  opacity: 0.5;
}

body.sonata-bc div.wrapper table.table thead th.sonata-ba-list-field-header-order-asc.sonata-ba-list-field-order-active a:hover:before,
body.sonata-bc .modal table.table thead th.sonata-ba-list-field-header-order-asc.sonata-ba-list-field-order-active a:hover:before {
  font-size: 14px;
  color: #F7893A;
  opacity: 1;
}

body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(odd),
body.sonata-bc .modal table.table tbody tr:nth-of-type(odd) {
  background: #fff !important;
}

body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(odd) th,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(odd) td,
body.sonata-bc .modal table.table tbody tr:nth-of-type(odd) th,
body.sonata-bc .modal table.table tbody tr:nth-of-type(odd) td {
  background: #fff !important;
}

body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(even),
body.sonata-bc .modal table.table tbody tr:nth-of-type(even) {
  background: #f5f5f5 !important;
}

body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(even) th,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(even) td,
body.sonata-bc .modal table.table tbody tr:nth-of-type(even) th,
body.sonata-bc .modal table.table tbody tr:nth-of-type(even) td {
  background: #f5f5f5 !important;
}

body.sonata-bc div.wrapper table.table tbody tr.sonata-ba-list-row-selected,
body.sonata-bc .modal table.table tbody tr.sonata-ba-list-row-selected {
  background-color: #c3c3c3 !important;
}

body.sonata-bc div.wrapper table.table tbody tr.sonata-ba-list-row-selected td,
body.sonata-bc div.wrapper table.table tbody tr.sonata-ba-list-row-selected th,
body.sonata-bc .modal table.table tbody tr.sonata-ba-list-row-selected td,
body.sonata-bc .modal table.table tbody tr.sonata-ba-list-row-selected th {
  background-color: #c3c3c3 !important;
}

body.sonata-bc div.wrapper table.table tbody tr th,
body.sonata-bc .modal table.table tbody tr th {
  border-color: transparent;
}

body.sonata-bc div.wrapper table.table tbody tr th a,
body.sonata-bc .modal table.table tbody tr th a {
  color: #000;
}

body.sonata-bc div.wrapper table.table tbody tr th a:hover,
body.sonata-bc .modal table.table tbody tr th a:hover {
  color: #fff;
}

body.sonata-bc div.wrapper table.table tbody tr td,
body.sonata-bc .modal table.table tbody tr td {
  position: relative;
  border-color: transparent !important;
  padding-left: 10px;
  color: #000;
  border-right-color: transparent;
  border-left-color: transparent;
  overflow: visible;
}

body.sonata-bc div.wrapper table.table tbody tr td:after,
body.sonata-bc .modal table.table tbody tr td:after {
  position: absolute;
  width: 2px;
  height: 80%;
  right: 0;
  top: 10%;
  background: #f5f5f5;
  box-shadow: 2px 0px 6px rgba(0,0,0,0.05);
  content: "";
}

body.sonata-bc div.wrapper table.table tbody tr td:last-child:after,
body.sonata-bc .modal table.table tbody tr td:last-child:after {
  width: 0;
  background: transparent;
}

body.sonata-bc div.wrapper table.table tbody tr td .label,
body.sonata-bc .modal table.table tbody tr td .label {
  display: inherit;
}

body.sonata-bc div.wrapper table.table tbody tr td a,
body.sonata-bc .modal table.table tbody tr td a {
  color: #000;
  font-weight: 400;
}

body.sonata-bc div.wrapper table.table tbody tr td a.label,
body.sonata-bc .modal table.table tbody tr td a.label {
  background: #000;
  color: #fff;
}

body.sonata-bc div.wrapper table.table tbody tr td a.label.label-success,
body.sonata-bc .modal table.table tbody tr td a.label.label-success {
  color: #000 !important;
  margin: auto;
  display: block;
  font-weight: 400;
  margin-left: 0;
}

body.sonata-bc div.wrapper table.table tbody tr td a .label.label-default,
body.sonata-bc .modal table.table tbody tr td a .label.label-default {
  color: #fff;
}

body.sonata-bc div.wrapper table.table tbody tr td a.label-danger:hover,
body.sonata-bc div.wrapper table.table tbody tr td a.label-success:hover,
body.sonata-bc .modal table.table tbody tr td a.label-danger:hover,
body.sonata-bc .modal table.table tbody tr td a.label-success:hover {
  color: #f93 !important;
  background-color: white !important;
}

body.sonata-bc div.wrapper table.table tbody tr td .editable,
body.sonata-bc .modal table.table tbody tr td .editable {
  border: none;
}

body.sonata-bc div.wrapper table.table tbody tr td .editable span.label,
body.sonata-bc .modal table.table tbody tr td .editable span.label {
  background-color: #f93 !important;
  color: #000 !important;
  margin: auto;
  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 0;
}

body.sonata-bc div.wrapper table.table tbody tr td .editable span.label.label-danger,
body.sonata-bc .modal table.table tbody tr td .editable span.label.label-danger {
  background-color: #000 !important;
  color: #f93 !important;
}

body.sonata-bc div.wrapper table.table tbody tr td span.label,
body.sonata-bc .modal table.table tbody tr td span.label {
  background-color: #f93 !important;
  color: #000 !important;
  margin: auto;
  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 0;
}

body.sonata-bc div.wrapper table.table tbody tr td span.label.label-danger,
body.sonata-bc .modal table.table tbody tr td span.label.label-danger {
  background-color: #000 !important;
  color: #f93 !important;
}

body.sonata-bc div.wrapper table.table tbody tr td .btn-group,
body.sonata-bc .modal table.table tbody tr td .btn-group {
  display: inline-flex;
}

body.sonata-bc div.wrapper table.table tbody tr td .btn-group a,
body.sonata-bc .modal table.table tbody tr td .btn-group a {
  font-size: 0;
  background: #D0D0D0;
  border: none;
  display: inline-block;
}

body.sonata-bc div.wrapper table.table tbody tr td .btn-group a:hover,
body.sonata-bc .modal table.table tbody tr td .btn-group a:hover {
  background: #B5B5B5;
}

body.sonata-bc div.wrapper table.table tbody tr td .btn-group a i,
body.sonata-bc .modal table.table tbody tr td .btn-group a i {
  font-size: 14px;
}

body.sonata-bc div.wrapper table.table tbody tr td .btn-group a:not(:last-child):after,
body.sonata-bc .modal table.table tbody tr td .btn-group a:not(:last-child):after {
  position: absolute;
  content: "";
  width: 1.5px;
  height: 60%;
  right: 0;
  top: 20%;
  background: #000;
  z-index: 3;
}

body.sonata-bc div.wrapper table.table tbody tr td .btn-group.btn-group-justified a,
body.sonata-bc .modal table.table tbody tr td .btn-group.btn-group-justified a {
  display: inline-table;
}

body.sonata-bc div.wrapper table.table tbody tr td .field-actions a:not(:last-child):after,
body.sonata-bc .modal table.table tbody tr td .field-actions a:not(:last-child):after {
  position: absolute;
  content: "";
  width: 1.5px;
  height: 60%;
  right: 0;
  top: 20%;
  background: transparent;
  z-index: 10;
}

body.sonata-bc div.wrapper table.table tbody tr td table thead,
body.sonata-bc .modal table.table tbody tr td table thead {
  background: none;
}

body.sonata-bc div.wrapper table.table tbody tr td table thead tr,
body.sonata-bc .modal table.table tbody tr td table thead tr {
  background: none;
}

body.sonata-bc div.wrapper table.table tbody tr td table thead tr:nth-of-type(odd),
body.sonata-bc div.wrapper table.table tbody tr td table thead tr:nth-of-type(even),
body.sonata-bc .modal table.table tbody tr td table thead tr:nth-of-type(odd),
body.sonata-bc .modal table.table tbody tr td table thead tr:nth-of-type(even) {
  background: none;
}

body.sonata-bc div.wrapper table.table tbody tr td table thead tr th,
body.sonata-bc .modal table.table tbody tr td table thead tr th {
  font-weight: 400;
  color: #000;
}

body.sonata-bc div.wrapper table.table tbody tr td table tbody tr,
body.sonata-bc .modal table.table tbody tr td table tbody tr {
  background: none;
}

body.sonata-bc div.wrapper table.table tbody tr td table tbody tr:nth-of-type(odd),
body.sonata-bc div.wrapper table.table tbody tr td table tbody tr:nth-of-type(even),
body.sonata-bc .modal table.table tbody tr td table tbody tr:nth-of-type(odd),
body.sonata-bc .modal table.table tbody tr td table tbody tr:nth-of-type(even) {
  background: none;
}

body.sonata-bc div.wrapper table.table tbody tr td table tbody tr:hover,
body.sonata-bc .modal table.table tbody tr td table tbody tr:hover {
  background: none;
}

body.sonata-bc div.wrapper table.table tbody tr td .label.label-info,
body.sonata-bc .modal table.table tbody tr td .label.label-info {
  display: inherit;
  margin-bottom: 10px;
  color: #000;
}

body.sonata-bc div.wrapper table.table tbody tr:hover,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(odd):hover,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(even):hover,
body.sonata-bc .modal table.table tbody tr:hover,
body.sonata-bc .modal table.table tbody tr:nth-of-type(odd):hover,
body.sonata-bc .modal table.table tbody tr:nth-of-type(even):hover {
  background: #6f6f6f !important;
}

body.sonata-bc div.wrapper table.table tbody tr:hover td,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(odd):hover td,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(even):hover td,
body.sonata-bc .modal table.table tbody tr:hover td,
body.sonata-bc .modal table.table tbody tr:nth-of-type(odd):hover td,
body.sonata-bc .modal table.table tbody tr:nth-of-type(even):hover td {
  color: #fff;
  background: #6f6f6f !important;
}

body.sonata-bc div.wrapper table.table tbody tr:hover th,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(odd):hover th,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(even):hover th,
body.sonata-bc .modal table.table tbody tr:hover th,
body.sonata-bc .modal table.table tbody tr:nth-of-type(odd):hover th,
body.sonata-bc .modal table.table tbody tr:nth-of-type(even):hover th {
  background: #6f6f6f !important;
}

body.sonata-bc div.wrapper table.table tbody tr:hover th a,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(odd):hover th a,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(even):hover th a,
body.sonata-bc .modal table.table tbody tr:hover th a,
body.sonata-bc .modal table.table tbody tr:nth-of-type(odd):hover th a,
body.sonata-bc .modal table.table tbody tr:nth-of-type(even):hover th a {
  color: #fff;
}

body.sonata-bc div.wrapper table.table tbody tr:hover th a:hover,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(odd):hover th a:hover,
body.sonata-bc div.wrapper table.table tbody tr:nth-of-type(even):hover th a:hover,
body.sonata-bc .modal table.table tbody tr:hover th a:hover,
body.sonata-bc .modal table.table tbody tr:nth-of-type(odd):hover th a:hover,
body.sonata-bc .modal table.table tbody tr:nth-of-type(even):hover th a:hover {
  color: #f93;
}

body.sonata-bc div.wrapper table.table tbody tr.history-audit-compare .diff,
body.sonata-bc .modal table.table tbody tr.history-audit-compare .diff {
  background: #f93;
  color: #000;
}

body.sonata-bc div.wrapper table.table tbody tr.mosaic-row:hover,
body.sonata-bc div.wrapper table.table tbody tr.mosaic-row:nth-of-type(odd):hover,
body.sonata-bc div.wrapper table.table tbody tr.mosaic-row:nth-of-type(even):hover,
body.sonata-bc .modal table.table tbody tr.mosaic-row:hover,
body.sonata-bc .modal table.table tbody tr.mosaic-row:nth-of-type(odd):hover,
body.sonata-bc .modal table.table tbody tr.mosaic-row:nth-of-type(even):hover {
  background: transparent !important;
}

body.sonata-bc div.wrapper table.table tbody tr.mosaic-row:hover td,
body.sonata-bc div.wrapper table.table tbody tr.mosaic-row:hover th,
body.sonata-bc div.wrapper table.table tbody tr.mosaic-row:nth-of-type(odd):hover td,
body.sonata-bc div.wrapper table.table tbody tr.mosaic-row:nth-of-type(odd):hover th,
body.sonata-bc div.wrapper table.table tbody tr.mosaic-row:nth-of-type(even):hover td,
body.sonata-bc div.wrapper table.table tbody tr.mosaic-row:nth-of-type(even):hover th,
body.sonata-bc .modal table.table tbody tr.mosaic-row:hover td,
body.sonata-bc .modal table.table tbody tr.mosaic-row:hover th,
body.sonata-bc .modal table.table tbody tr.mosaic-row:nth-of-type(odd):hover td,
body.sonata-bc .modal table.table tbody tr.mosaic-row:nth-of-type(odd):hover th,
body.sonata-bc .modal table.table tbody tr.mosaic-row:nth-of-type(even):hover td,
body.sonata-bc .modal table.table tbody tr.mosaic-row:nth-of-type(even):hover th {
  background: transparent !important;
}

body.sonata-bc div.wrapper table.table.table-striped tbody tr:nth-of-type(odd),
body.sonata-bc .modal table.table.table-striped tbody tr:nth-of-type(odd) {
  background: #f5f5f5;
}

body.sonata-bc div.wrapper table.table.table-striped tbody tr:nth-of-type(even),
body.sonata-bc .modal table.table.table-striped tbody tr:nth-of-type(even) {
  background: #fff;
}

body.sonata-bc div.wrapper table.table.table-striped tbody tr td,
body.sonata-bc .modal table.table.table-striped tbody tr td {
  border-color: #f9f9f9;
}

body.sonata-bc div.wrapper form div.box-footer,
body.sonata-bc .modal form div.box-footer {
  border-color: white;
  padding-right: 0;
}

body.sonata-bc div.wrapper form div.box-footer label,
body.sonata-bc .modal form div.box-footer label {
  color: #000;
  margin-right: 20px;
}

body.sonata-bc div.wrapper form div.box-footer div.select2-container .select2-choice,
body.sonata-bc .modal form div.box-footer div.select2-container .select2-choice {
  color: #000;
  font-weight: 400;
  border: 1px solid #000;
  border-radius: 5px !important;
  box-shadow: none;
  background: #fff;
}

body.sonata-bc div.wrapper form div.box-footer div.select2-container.select2-dropdown-open .select2-choice,
body.sonata-bc .modal form div.box-footer div.select2-container.select2-dropdown-open .select2-choice {
  border-radius: 5px 5px 0 0 !important;
  border-bottom: 1px solid #000;
}

body.sonata-bc div.wrapper form div.box-footer input[type=submit],
body.sonata-bc .modal form div.box-footer input[type=submit] {
  background: #f93;
  border: none;
  border-radius: 5px;
  color: #000;
  font-weight: 400;
  margin-left: 20px;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

body.sonata-bc div.wrapper form div.box-footer input[type=submit]:hover,
body.sonata-bc .modal form div.box-footer input[type=submit]:hover {
  background: #F7893A;
}

body.sonata-bc div.wrapper form div.box-footer .pull-right,
body.sonata-bc .modal form div.box-footer .pull-right {
  font-weight: 700;
}

body.sonata-bc div.wrapper form div.box-footer .pull-right label,
body.sonata-bc .modal form div.box-footer .pull-right label {
  vertical-align: unset;
}

body.sonata-bc div.wrapper form div.box-footer .pull-right button.btn-default,
body.sonata-bc .modal form div.box-footer .pull-right button.btn-default {
  border: 1px solid #000;
  border-radius: 5px;
  background: transparent;
  color: #000;
  font-weight: 400;
}

body.sonata-bc div.wrapper form div.box-footer .pull-right .btn-group,
body.sonata-bc .modal form div.box-footer .pull-right .btn-group {
  position: relative;
  float: left;
  margin-right: 20px;
}

body.sonata-bc div.wrapper form div.box-footer .pull-right .btn-group.open button.btn,
body.sonata-bc .modal form div.box-footer .pull-right .btn-group.open button.btn {
  border-radius: 5px 5px 0px 0px;
}

body.sonata-bc div.wrapper form div.box-footer .pull-right .btn-group.open .dropdown-toggle,
body.sonata-bc .modal form div.box-footer .pull-right .btn-group.open .dropdown-toggle {
  box-shadow: none;
}

body.sonata-bc div.wrapper form div.box-footer .pull-right .btn-group ul.dropdown-menu,
body.sonata-bc .modal form div.box-footer .pull-right .btn-group ul.dropdown-menu {
  clear: both;
  width: 100%;
  min-width: auto;
  border-radius: 0 0 5px 5px;
  border: 1px solid #000;
  margin-top: -1px;
  padding: 5px 7px;
  background: #fff;
}

body.sonata-bc div.wrapper form div.box-footer .pull-right .btn-group ul.dropdown-menu>li>a,
body.sonata-bc .modal form div.box-footer .pull-right .btn-group ul.dropdown-menu>li>a {
  border-radius: 5px;
  color: #000;
}

body.sonata-bc div.wrapper form div.box-footer .pull-right .btn-group ul.dropdown-menu>li>a i,
body.sonata-bc .modal form div.box-footer .pull-right .btn-group ul.dropdown-menu>li>a i {
  display: none;
}

body.sonata-bc div.wrapper form div.box-footer .pull-right .btn-group ul.dropdown-menu>li>a:hover,
body.sonata-bc .modal form div.box-footer .pull-right .btn-group ul.dropdown-menu>li>a:hover {
  background: #F7893A;
  color: #000 !important;
}

body {
  color: #000;
}

body.sonata-bc div.wrapper {
  background-color: #fff;
}

body.sonata-bc div.wrapper header {
  margin-bottom: 0px;
}

body.sonata-bc div.wrapper header .alert.alert-warning {
  position: fixed;
  width: 60%;
  z-index: 10000;
  left: 20%;
  color: #fff !important;
  top: -20px;
  opacity: 0;
  padding: 30px 15px;
  background: #d0362a !important;
  box-shadow: 3px 3px 12px rgba(0,0,0,0.3);
  animation-name: alert-fade-in;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 800ms;
  -webkit-animation-name: alert-fade-in;
  -webkit-animation-duration: 400ms;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 800ms;
  -ms-animation-name: alert-fade-in;
  -ms-animation-duration: 400ms;
  -ms-animation-iteration-count: 1;
  -ms-animation-fill-mode: forwards;
  -ms-animation-delay: 800ms;
  -moz-animation-name: alert-fade-in;
  -moz-animation-duration: 400ms;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 800ms;
  -o-animation-name: alert-fade-in;
  -o-animation-duration: 400ms;
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 800ms;
}

body.sonata-bc div.wrapper header .alert h4 {
  padding: 0 30px 0 20px;
  line-height: 1.5;
}

body.sonata-bc div.wrapper header .alert.alert-dismissable .close {
  right: 20px;
  font-size: 28px;
  color: #fff;
  position: absolute;
  opacity: 0.8;
  line-height: 1;
  top: 50%;
  transform: translate(0px, -60%);
}

body.sonata-bc div.wrapper header .alert.alert-dismissable .close:hover {
  opacity: 1;
}

body.sonata-bc div.wrapper .content form .form-group input[type="text"],
body.sonata-bc div.wrapper .content form .form-group input[type="number"],
body.sonata-bc div.wrapper .content form .form-group input[type="url"],
body.sonata-bc div.wrapper .content form .form-group input[type="email"],
body.sonata-bc div.wrapper .content form .form-group input[type="password"] {
  border-bottom: 1px solid #000;
  background: #f5f5f5;
}

body.sonata-bc div.wrapper .content form .form-group div.select2-container .select2-choices input[type="text"] {
  background: #f5f5f5;
}

body.sonata-bc div.wrapper .box,
body.sonata-bc .modal .box,
body.sonata-bc .ui-dialog .box {
  box-shadow: none;
}

body.sonata-bc div.wrapper .box.box-primary,
body.sonata-bc .modal .box.box-primary,
body.sonata-bc .ui-dialog .box.box-primary {
  border: none;
  box-shadow: none;
}

body.sonata-bc div.wrapper .box.box-primary .box-header .box-title,
body.sonata-bc div.wrapper .box.box-primary .modal-header .box-title,
body.sonata-bc div.wrapper .box.box-primary .ui-dialog-titlebar .box-title,
body.sonata-bc .modal .box.box-primary .box-header .box-title,
body.sonata-bc .modal .box.box-primary .modal-header .box-title,
body.sonata-bc .modal .box.box-primary .ui-dialog-titlebar .box-title,
body.sonata-bc .ui-dialog .box.box-primary .box-header .box-title,
body.sonata-bc .ui-dialog .box.box-primary .modal-header .box-title,
body.sonata-bc .ui-dialog .box.box-primary .ui-dialog-titlebar .box-title {
  font-weight: 500;
  color: #000;
}

body.sonata-bc div.wrapper .box.box-primary .box-header .btn-group,
body.sonata-bc div.wrapper .box.box-primary .modal-header .btn-group,
body.sonata-bc div.wrapper .box.box-primary .ui-dialog-titlebar .btn-group,
body.sonata-bc .modal .box.box-primary .box-header .btn-group,
body.sonata-bc .modal .box.box-primary .modal-header .btn-group,
body.sonata-bc .modal .box.box-primary .ui-dialog-titlebar .btn-group,
body.sonata-bc .ui-dialog .box.box-primary .box-header .btn-group,
body.sonata-bc .ui-dialog .box.box-primary .modal-header .btn-group,
body.sonata-bc .ui-dialog .box.box-primary .ui-dialog-titlebar .btn-group {
  margin-left: 20px;
  border: 1px solid #000;
  box-shadow: none;
  border-radius: 5px;
}

body.sonata-bc div.wrapper .box.box-primary .box-header .btn-group .btn,
body.sonata-bc div.wrapper .box.box-primary .modal-header .btn-group .btn,
body.sonata-bc div.wrapper .box.box-primary .ui-dialog-titlebar .btn-group .btn,
body.sonata-bc .modal .box.box-primary .box-header .btn-group .btn,
body.sonata-bc .modal .box.box-primary .modal-header .btn-group .btn,
body.sonata-bc .modal .box.box-primary .ui-dialog-titlebar .btn-group .btn,
body.sonata-bc .ui-dialog .box.box-primary .box-header .btn-group .btn,
body.sonata-bc .ui-dialog .box.box-primary .modal-header .btn-group .btn,
body.sonata-bc .ui-dialog .box.box-primary .ui-dialog-titlebar .btn-group .btn {
  background: #fff;
  border: none;
}

body.sonata-bc div.wrapper .box.box-primary .box-header .btn-group .btn .text-info,
body.sonata-bc div.wrapper .box.box-primary .modal-header .btn-group .btn .text-info,
body.sonata-bc div.wrapper .box.box-primary .ui-dialog-titlebar .btn-group .btn .text-info,
body.sonata-bc .modal .box.box-primary .box-header .btn-group .btn .text-info,
body.sonata-bc .modal .box.box-primary .modal-header .btn-group .btn .text-info,
body.sonata-bc .modal .box.box-primary .ui-dialog-titlebar .btn-group .btn .text-info,
body.sonata-bc .ui-dialog .box.box-primary .box-header .btn-group .btn .text-info,
body.sonata-bc .ui-dialog .box.box-primary .modal-header .btn-group .btn .text-info,
body.sonata-bc .ui-dialog .box.box-primary .ui-dialog-titlebar .btn-group .btn .text-info {
  color: #000;
}

body.sonata-bc div.wrapper .box.box-primary .box-header .btn-group .btn:hover,
body.sonata-bc div.wrapper .box.box-primary .modal-header .btn-group .btn:hover,
body.sonata-bc div.wrapper .box.box-primary .ui-dialog-titlebar .btn-group .btn:hover,
body.sonata-bc .modal .box.box-primary .box-header .btn-group .btn:hover,
body.sonata-bc .modal .box.box-primary .modal-header .btn-group .btn:hover,
body.sonata-bc .modal .box.box-primary .ui-dialog-titlebar .btn-group .btn:hover,
body.sonata-bc .ui-dialog .box.box-primary .box-header .btn-group .btn:hover,
body.sonata-bc .ui-dialog .box.box-primary .modal-header .btn-group .btn:hover,
body.sonata-bc .ui-dialog .box.box-primary .ui-dialog-titlebar .btn-group .btn:hover {
  background: #fff;
  color: #F7893A;
}

body.sonata-bc div.wrapper .box.box-primary .box-header .btn-group .btn:hover .text-info,
body.sonata-bc div.wrapper .box.box-primary .modal-header .btn-group .btn:hover .text-info,
body.sonata-bc div.wrapper .box.box-primary .ui-dialog-titlebar .btn-group .btn:hover .text-info,
body.sonata-bc .modal .box.box-primary .box-header .btn-group .btn:hover .text-info,
body.sonata-bc .modal .box.box-primary .modal-header .btn-group .btn:hover .text-info,
body.sonata-bc .modal .box.box-primary .ui-dialog-titlebar .btn-group .btn:hover .text-info,
body.sonata-bc .ui-dialog .box.box-primary .box-header .btn-group .btn:hover .text-info,
body.sonata-bc .ui-dialog .box.box-primary .modal-header .btn-group .btn:hover .text-info,
body.sonata-bc .ui-dialog .box.box-primary .ui-dialog-titlebar .btn-group .btn:hover .text-info {
  color: #F7893A;
}

body.sonata-bc div.wrapper .box.box-primary .box-header .btn-group .dropdown-menu,
body.sonata-bc div.wrapper .box.box-primary .modal-header .btn-group .dropdown-menu,
body.sonata-bc div.wrapper .box.box-primary .ui-dialog-titlebar .btn-group .dropdown-menu,
body.sonata-bc .modal .box.box-primary .box-header .btn-group .dropdown-menu,
body.sonata-bc .modal .box.box-primary .modal-header .btn-group .dropdown-menu,
body.sonata-bc .modal .box.box-primary .ui-dialog-titlebar .btn-group .dropdown-menu,
body.sonata-bc .ui-dialog .box.box-primary .box-header .btn-group .dropdown-menu,
body.sonata-bc .ui-dialog .box.box-primary .modal-header .btn-group .dropdown-menu,
body.sonata-bc .ui-dialog .box.box-primary .ui-dialog-titlebar .btn-group .dropdown-menu {
  margin-left: -1px;
}

body.sonata-bc div.wrapper .box.box-primary .box-header .btn-group .dropdown-menu a,
body.sonata-bc div.wrapper .box.box-primary .modal-header .btn-group .dropdown-menu a,
body.sonata-bc div.wrapper .box.box-primary .ui-dialog-titlebar .btn-group .dropdown-menu a,
body.sonata-bc .modal .box.box-primary .box-header .btn-group .dropdown-menu a,
body.sonata-bc .modal .box.box-primary .modal-header .btn-group .dropdown-menu a,
body.sonata-bc .modal .box.box-primary .ui-dialog-titlebar .btn-group .dropdown-menu a,
body.sonata-bc .ui-dialog .box.box-primary .box-header .btn-group .dropdown-menu a,
body.sonata-bc .ui-dialog .box.box-primary .modal-header .btn-group .dropdown-menu a,
body.sonata-bc .ui-dialog .box.box-primary .ui-dialog-titlebar .btn-group .dropdown-menu a {
  color: #000;
}

body.sonata-bc div.wrapper .box.box-primary .box-content .sonata-tree__item,
body.sonata-bc .modal .box.box-primary .box-content .sonata-tree__item,
body.sonata-bc .ui-dialog .box.box-primary .box-content .sonata-tree__item {
  color: #000;
  border: 1px solid black;
  padding: 12px;
}

body.sonata-bc div.wrapper .box.box-primary .box-content .sonata-tree__item a,
body.sonata-bc .modal .box.box-primary .box-content .sonata-tree__item a,
body.sonata-bc .ui-dialog .box.box-primary .box-content .sonata-tree__item a {
  margin-right: 10px;
}

body.sonata-bc div.wrapper .box.box-primary .box-content .sonata-tree__item:hover,
body.sonata-bc .modal .box.box-primary .box-content .sonata-tree__item:hover,
body.sonata-bc .ui-dialog .box.box-primary .box-content .sonata-tree__item:hover {
  background: #f5f5f5;
}

body.sonata-bc div.wrapper .box.box-primary .box-content .sonata-tree__item span.label,
body.sonata-bc .modal .box.box-primary .box-content .sonata-tree__item span.label,
body.sonata-bc .ui-dialog .box.box-primary .box-content .sonata-tree__item span.label {
  border: none;
  background-color: #f93 !important;
  color: #000 !important;
  font-weight: 400;
}

body.sonata-bc div.wrapper .box.box-primary .box-content a,
body.sonata-bc .modal .box.box-primary .box-content a,
body.sonata-bc .ui-dialog .box.box-primary .box-content a {
  color: #000;
  font-weight: 400;
}

body.sonata-bc div.wrapper .box.box-primary .box-body .info-box,
body.sonata-bc .modal .box.box-primary .box-body .info-box,
body.sonata-bc .ui-dialog .box.box-primary .box-body .info-box {
  box-shadow: none;
  background: transparent;
}

body.sonata-bc div.wrapper .box.box-primary .box-body .info-box .info-box-icon,
body.sonata-bc .modal .box.box-primary .box-body .info-box .info-box-icon,
body.sonata-bc .ui-dialog .box.box-primary .box-body .info-box .info-box-icon {
  position: relative;
  background: #f5f5f5 !important;
  border: none;
  border-radius: 10px;
}

body.sonata-bc div.wrapper .box.box-primary .box-body .info-box .info-box-icon i,
body.sonata-bc .modal .box.box-primary .box-body .info-box .info-box-icon i,
body.sonata-bc .ui-dialog .box.box-primary .box-body .info-box .info-box-icon i {
  position: absolute;
  color: #000;
  font-size: 36px;
  top: 50%;
  left: 50%;
  transform: translate(-46%, -50%);
}

body.sonata-bc div.wrapper .box.box-primary .box-body .info-box .info-box-icon i:before,
body.sonata-bc .modal .box.box-primary .box-body .info-box .info-box-icon i:before,
body.sonata-bc .ui-dialog .box.box-primary .box-body .info-box .info-box-icon i:before {
  font-family: "FontAwesome";
  content: "\f061";
}

body.sonata-bc div.wrapper .box.box-primary .box-body .info-box .progress,
body.sonata-bc .modal .box.box-primary .box-body .info-box .progress,
body.sonata-bc .ui-dialog .box.box-primary .box-body .info-box .progress {
  background: black;
  margin-left: 0px;
}

body.sonata-bc div.wrapper .box.box-primary .box-body .info-box .progress-description ul li a,
body.sonata-bc .modal .box.box-primary .box-body .info-box .progress-description ul li a,
body.sonata-bc .ui-dialog .box.box-primary .box-body .info-box .progress-description ul li a {
  color: #000;
}

body.sonata-bc div.wrapper .box.box-primary .box-body .info-box .info-box-text,
body.sonata-bc .modal .box.box-primary .box-body .info-box .info-box-text,
body.sonata-bc .ui-dialog .box.box-primary .box-body .info-box .info-box-text {
  color: #000;
  font-weight: 400;
}

body.sonata-bc div.wrapper .box.box-primary .box-footer,
body.sonata-bc .modal .box.box-primary .box-footer,
body.sonata-bc .ui-dialog .box.box-primary .box-footer {
  margin-top: 40px;
}

body.sonata-bc div.wrapper .box .box-header .box-title,
body.sonata-bc div.wrapper .box .modal-header .box-title,
body.sonata-bc div.wrapper .box .ui-dialog-titlebar .box-title,
body.sonata-bc .modal .box .box-header .box-title,
body.sonata-bc .modal .box .modal-header .box-title,
body.sonata-bc .modal .box .ui-dialog-titlebar .box-title,
body.sonata-bc .ui-dialog .box .box-header .box-title,
body.sonata-bc .ui-dialog .box .modal-header .box-title,
body.sonata-bc .ui-dialog .box .ui-dialog-titlebar .box-title {
  color: #000;
}

body.sonata-bc div.wrapper .navbar,
body.sonata-bc .modal .navbar,
body.sonata-bc .ui-dialog .navbar {
  border: none;
  background: #fff;
}

body.sonata-bc div.wrapper .navbar .navbar-collapse,
body.sonata-bc .modal .navbar .navbar-collapse,
body.sonata-bc .ui-dialog .navbar .navbar-collapse {
  border: none;
  background: #fff;
  padding-left: 0;
  padding-right: 0;
}

body.sonata-bc div.wrapper .navbar .navbar-collapse .navbar-right,
body.sonata-bc .modal .navbar .navbar-collapse .navbar-right,
body.sonata-bc .ui-dialog .navbar .navbar-collapse .navbar-right {
  margin-right: 0;
}

body.sonata-bc div.wrapper .navbar .navbar-collapse .navbar-right li,
body.sonata-bc .modal .navbar .navbar-collapse .navbar-right li,
body.sonata-bc .ui-dialog .navbar .navbar-collapse .navbar-right li {
  margin-left: 25px;
}

body.sonata-bc div.wrapper .navbar.stuck,
body.sonata-bc .modal .navbar.stuck,
body.sonata-bc .ui-dialog .navbar.stuck {
  margin-left: -80px;
}

body.sonata-bc div.wrapper .navbar.stuck .container-fluid,
body.sonata-bc .modal .navbar.stuck .container-fluid,
body.sonata-bc .ui-dialog .navbar.stuck .container-fluid {
  margin-right: 80px;
  margin-left: 80px;
}

body.sonata-bc div.wrapper .navbar .navbar-collapse .navbar-left ul.nav>li>a,
body.sonata-bc .modal .navbar .navbar-collapse .navbar-left ul.nav>li>a,
body.sonata-bc .ui-dialog .navbar .navbar-collapse .navbar-left ul.nav>li>a {
  background: #fff;
  border: 1px solid #000;
  color: #000;
  border-radius: 5px;
  padding: 6px 12px;
}

body.sonata-bc div.wrapper .navbar .navbar-collapse .navbar-left ul.nav>li>a i,
body.sonata-bc .modal .navbar .navbar-collapse .navbar-left ul.nav>li>a i,
body.sonata-bc .ui-dialog .navbar .navbar-collapse .navbar-left ul.nav>li>a i {
  display: none;
}

body.sonata-bc div.wrapper .alert.alert-warning,
body.sonata-bc .modal .alert.alert-warning,
body.sonata-bc .ui-dialog .alert.alert-warning {
  box-shadow: 3px 3px 8px rgba(0,0,0,0.2);
  background: #f93 !important;
  border: none;
  color: #000 !important;
  font-weight: 400;
}

body.sonata-bc div.wrapper .alert .close,
body.sonata-bc .modal .alert .close,
body.sonata-bc .ui-dialog .alert .close {
  text-shadow: none;
}

body.sonata-bc div.wrapper .alert .close:hover,
body.sonata-bc .modal .alert .close:hover,
body.sonata-bc .ui-dialog .alert .close:hover {
  opacity: 1;
}

body.sonata-bc div.wrapper .sonata-ba-content .box-success,
body.sonata-bc .modal .sonata-ba-content .box-success,
body.sonata-bc .ui-dialog .sonata-ba-content .box-success {
  border-top: 3px solid #f93;
  box-shadow: none;
  border-right: none;
}

body.sonata-bc div.wrapper .sonata-ba-content .box-success .box-title,
body.sonata-bc .modal .sonata-ba-content .box-success .box-title,
body.sonata-bc .ui-dialog .sonata-ba-content .box-success .box-title {
  color: #000;
  font-weight: 400;
}

body.sonata-bc div.wrapper .sonata-ba-content .box-success .box-body,
body.sonata-bc .modal .sonata-ba-content .box-success .box-body,
body.sonata-bc .ui-dialog .sonata-ba-content .box-success .box-body {
  display: flex;
  margin: 20px 0;
}

body.sonata-bc div.wrapper .sonata-ba-content .box-success .box-body div,
body.sonata-bc .modal .sonata-ba-content .box-success .box-body div,
body.sonata-bc .ui-dialog .sonata-ba-content .box-success .box-body div {
  margin: auto;
}

body.sonata-bc div.wrapper .sonata-ba-content .box-success .box-body div a,
body.sonata-bc .modal .sonata-ba-content .box-success .box-body div a,
body.sonata-bc .ui-dialog .sonata-ba-content .box-success .box-body div a {
  color: #000;
  font-weight: 400;
}

body.sonata-bc div.wrapper .sonata-ba-content .box-success .box-body div:nth-child(1) .btn-app:after,
body.sonata-bc .modal .sonata-ba-content .box-success .box-body div:nth-child(1) .btn-app:after,
body.sonata-bc .ui-dialog .sonata-ba-content .box-success .box-body div:nth-child(1) .btn-app:after {
  position: absolute;
  background: transparent;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 58%;
  mix-blend-mode: screen;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

body.sonata-bc div.wrapper .sonata-ba-content .box-success .btn-app,
body.sonata-bc .modal .sonata-ba-content .box-success .btn-app,
body.sonata-bc .ui-dialog .sonata-ba-content .box-success .btn-app {
  background: #fff;
  border: 1px solid #000;
}

body.sonata-bc div.wrapper .revision-detail .box-primary,
body.sonata-bc .modal .revision-detail .box-primary,
body.sonata-bc .ui-dialog .revision-detail .box-primary {
  border-radius: 0;
  border-top: 2px solid #f93;
  margin: 0px 0 40px 0;
}

body.sonata-bc div.wrapper .revision-detail .box-primary .box-header,
body.sonata-bc .modal .revision-detail .box-primary .box-header,
body.sonata-bc .ui-dialog .revision-detail .box-primary .box-header {
  text-align: left;
  padding-left: 0;
  font-weight: 500;
}

body.sonata-bc div.wrapper .nav-tabs-custom,
body.sonata-bc .modal .nav-tabs-custom,
body.sonata-bc .ui-dialog .nav-tabs-custom {
  box-shadow: none;
  background: #fff !important;
}

body.sonata-bc div.wrapper .nav-tabs-custom>.tab-content,
body.sonata-bc .modal .nav-tabs-custom>.tab-content,
body.sonata-bc .ui-dialog .nav-tabs-custom>.tab-content {
  background: #fff;
}

body ul.select2-results {
  color: #000;
  background: #fff;
  border-radius: 5px;
  padding: 5px 7px;
  margin: 4px 4px 4px 4px;
}

body ul.select2-results .select2-highlighted {
  color: #000;
  background: #F7893A;
  border-radius: 5px;
}

body ul.select2-results .select2-no-results {
  background: #f5f5f5;
  color: #000;
  border-radius: 5px;
}

body ul.select2-results .select2-searching {
  background: #f5f5f5 !important;
  color: #000;
  border-radius: 5px;
}

body div.select2-search {
  box-shadow: none;
}

body div.select2-search input {
  box-shadow: none;
  border: none;
  background: #fff;
  color: #000;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

body div.select2-search input.select2-active {
  background: #e1e0dd url(/admin/bundles/sonatacore/vendor/select2/select2-spinner.gif) no-repeat 100%;
  background: url(/admin/bundles/sonatacore/vendor/select2/select2-spinner.gif) no-repeat 100%,-webkit-gradient(linear, left bottom, left top, color-stop(0.85, #e1e0dd), color-stop(0.99, #e1e0dd));
  background: url(/admin/bundles/sonatacore/vendor/select2/select2-spinner.gif) no-repeat 100%,-webkit-linear-gradient(center bottom, #e1e0dd 85%, #e1e0dd 99%);
  background: url(/admin/bundles/sonatacore/vendor/select2/select2-spinner.gif) no-repeat 100%,-moz-linear-gradient(center bottom, #e1e0dd 85%, #e1e0dd 99%);
  background: url(/admin/bundles/sonatacore/vendor/select2/select2-spinner.gif) no-repeat 100%,linear-gradient(to bottom, #e1e0dd 85%, #e1e0dd 99%) 0 0;
  border-bottom: 1px solid #000;
  filter: invert(1);
  color: #fff;
}

body div.select2-search input.select2-active::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 106%;
  height: 106%;
  content: "";
  background: red;
}

body .select2-drop {
  box-shadow: none;
  border: 1px solid #000;
  border-top: 1px solid #000;
  border-radius: 0 0 5px 5px !important;
  background: #fff;
}

body button.btn.btn-primary,
body a.btn.btn-primary {
  font-weight: 400;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  margin-top: 10px;
}

body button.btn.btn-primary:hover,
body a.btn.btn-primary:hover {
  background: #F7893A !important;
  color: white !important;
}

div.wrapper div.content-wrapper {
  background: #fff;
  margin-left: 200px;
  padding-top: 160px !important;
}

div.wrapper div.content-wrapper .content-header {
  padding: 15px 80px 20px 80px;
}

div.wrapper div.content-wrapper .content-header nav .container-fluid {
  border-bottom: 3px solid #f93;
  padding: 20px 0 10px 0;
  background: #fff;
}

div.wrapper div.content-wrapper .content-header nav .container-fluid a.navbar-brand {
  font-weight: 400;
  color: #000;
}

div.wrapper div.content-wrapper .content-header div.btn-group a {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #000;
  font-weight: 400;
  border-radius: 5px !important;
  margin: 0;
  padding: 0;
}

div.wrapper div.content-wrapper .content-header div.btn-group a.active {
  border: none;
  display: none;
}

div.wrapper div.content-wrapper .content-header div.btn-group a.active i {
  color: #f93;
}

div.wrapper div.content-wrapper .content-header div.btn-group a i {
  font-size: 2rem;
  color: #000;
  padding: 0;
  margin: 0 0 0 25px;
  display: initial;
  -webkit-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -moz-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -o-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -ms-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
}

div.wrapper div.content-wrapper .content-header div.btn-group a:hover {
  color: #F7893A;
}

div.wrapper div.content-wrapper .content-header div.btn-group a:hover i {
  color: #F7893A;
}

div.wrapper div.content-wrapper .content-header .navbar-left ul.nav>li {
  padding: 15px 15px;
}

div.wrapper div.content-wrapper .content-header .navbar-left ul.nav>li a {
  font-size: 16px;
}

div.wrapper div.content-wrapper .content-header ul.nav>li.dropdown {
  margin-top: -1px;
}

div.wrapper div.content-wrapper .content-header ul.nav>li a {
  color: #000;
  font-size: 0;
  font-weight: 400;
}

div.wrapper div.content-wrapper .content-header ul.nav>li a i {
  font-size: 2.4rem;
}

div.wrapper div.content-wrapper .content-header ul.nav>li a.ga-filter-list-save {
  margin-top: 15px;
  background: #f5f5f5;
}

div.wrapper div.content-wrapper .content-header ul.nav>li>a {
  border: none;
  border-radius: 5px;
  display: initial;
  padding: 0;
}

div.wrapper div.content-wrapper .content-header ul.nav>li>a i {
  line-height: inherit;
}

div.wrapper div.content-wrapper .content-header ul.nav>li>a i.fa-plus-circle:before {
  font-family: "FontAwesome";
  content: "\f067";
}

div.wrapper div.content-wrapper .content-header ul.nav>li .dropdown-menu {
  border: 1px solid black;
  padding: 5px 7px;
  background: #f5f5f5;
}

div.wrapper div.content-wrapper .content-header ul.nav>li .dropdown-menu.ui-sortable {
  max-height: 50vh;
  overflow-y: scroll;
}

div.wrapper div.content-wrapper .content-header ul.nav>li .dropdown-menu.ui-sortable li:last-child {
  margin: 15px 0 10px 0;
}

div.wrapper div.content-wrapper .content-header ul.nav>li .dropdown-menu.ui-sortable li:last-child a {
  background: #f93;
  border: none;
  border-radius: 5px;
  color: #000;
  font-weight: 400;
}

div.wrapper div.content-wrapper .content-header ul.nav>li .dropdown-menu.ui-sortable li:last-child a:hover {
  background: #F7893A;
}

div.wrapper div.content-wrapper .content-header ul.nav>li .dropdown-menu a {
  font-size: 14px;
  padding: 3px 7px 4px;
  border-radius: 5px;
}

div.wrapper div.content-wrapper .content-header ul.nav>li .dropdown-menu a:hover {
  background: #F7893A;
  color: #000 !important;
}

div.wrapper div.content-wrapper .content-header ul.nav>li .dropdown-menu a:focus {
  background: transparent !important;
}

div.wrapper div.content-wrapper .content-header ul.nav>li .dropdown-menu a i {
  font-size: initial;
}

div.wrapper div.content-wrapper .content-header ul.nav>li:hover>a {
  color: #F7893A !important;
}

div.wrapper div.content-wrapper .content-header ul.nav>li:hover>a .badge {
  color: #F7893A;
}

div.wrapper div.content-wrapper .content-header ul.nav>li:hover>a .badge:after,
div.wrapper div.content-wrapper .content-header ul.nav>li:hover>a .badge:before {
  color: #F7893A !important;
}

div.wrapper div.content-wrapper .content-header ul.nav>li .badge {
  position: relative;
  background: transparent;
  color: #f93;
  border: none;
  padding: 5px 8px;
  border-radius: 15px;
  display: table-cell;
  -webkit-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -moz-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -o-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  -ms-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
  transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
}

div.wrapper div.content-wrapper .content-header ul.nav>li .badge:after {
  position: absolute;
  top: 48%;
  left: 10%;
  transform: translate(0, -50%);
  color: #000;
  content: '(';
}

div.wrapper div.content-wrapper .content-header ul.nav>li .badge:before {
  position: absolute;
  top: 48%;
  right: 10%;
  transform: translate(0, -50%);
  color: #000;
  content: ')';
}

div.wrapper div.content-wrapper .content-header ul.nav>li b.caret {
  display: none;
}

div.wrapper div.content-wrapper .content-header div.navbar-left .btn-group a {
  font-size: 0;
}

div.wrapper div.content-wrapper .content-header div.navbar-left .btn-group a i {
  margin-left: 0;
}

div.wrapper div.content-wrapper .content {
  padding: 0px 80px 80px 80px;
}

div.wrapper div.content-wrapper .content .flex-item {
  flex-basis: calc(25% - 40px);
  margin: 20px;
}

div.wrapper div.content-wrapper .content .flex-item .box-header {
  padding: 15px 10px;
}

div.wrapper div.content-wrapper .content .flex-item .box-header .box-title {
  position: relative;
  color: #000;
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.3px;
}

div.wrapper div.content-wrapper .content .flex-item .box-header:before {
  position: absolute;
  content: " ";
  display: initial;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 -1px 15px rgba(0,0,0,0.15);
}

div.wrapper div.content-wrapper .content .flex-item .box {
  background: #f5f5f5;
  box-shadow: none;
  border-top: 0;
  overflow: hidden;
}

div.wrapper div.content-wrapper .content .flex-item .box-body {
  padding-top: 20px;
}

div.wrapper div.content-wrapper .content .flex-item .box-body li {
  margin-left: 10px;
}

div.wrapper div.content-wrapper .content .flex-item .box-body li a {
  color: #000;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.3px;
}

div.wrapper div.content-wrapper .content .flex-item .box-body li :before {
  position: relative;
  font-family: "FontAwesome";
  content: "\f105";
  color: #000;
  left: -8px;
  font-size: 1.8rem;
}

div.wrapper div.content-wrapper .content .flex-item .box-body li .badge {
  padding: 6px 9px;
  border-radius: 30px;
}

div.wrapper div.content-wrapper .content .flex-item .box-body li .badge:before {
  display: none;
}

div.wrapper div.content-wrapper .content form .box.box-solid.box-primary .box-header {
  background: transparent;
  color: #000;
}

div.wrapper div.content-wrapper .content form .box-header {
  text-align: left;
  padding-left: 0;
  padding-right: 0;
}

div.wrapper div.content-wrapper .content form .box-body {
  padding-left: 0;
  padding-right: 0;
}

div.wrapper div.content-wrapper .content .sonata-filter-form .form-group {
  text-align: right;
  margin-right: -10px;
}

div.wrapper div.content-wrapper .content .form-group {
  margin-bottom: 20px;
  position: relative;
}

div.wrapper div.content-wrapper .content .form-group .sonata-ba-field a {
  color: #f93;
}

div.wrapper div.content-wrapper .content .form-group .sonata-ba-field a:hover {
  color: #F7893A;
}

div.wrapper div.content-wrapper .content .form-group label {
  margin-bottom: 0;
  color: #000;
}

div.wrapper div.content-wrapper .content .form-group input[type="text"],
div.wrapper div.content-wrapper .content .form-group input[type="number"],
div.wrapper div.content-wrapper .content .form-group input[type="url"],
div.wrapper div.content-wrapper .content .form-group input[type="email"],
div.wrapper div.content-wrapper .content .form-group input[type="password"] {
  background: #fff;
  border: none;
  border-bottom: 1px solid transparent;
  color: #000;
}

div.wrapper div.content-wrapper .content .form-group input[type="text"]:focus,
div.wrapper div.content-wrapper .content .form-group input[type="number"]:focus,
div.wrapper div.content-wrapper .content .form-group input[type="url"]:focus,
div.wrapper div.content-wrapper .content .form-group input[type="email"]:focus,
div.wrapper div.content-wrapper .content .form-group input[type="password"]:focus {
  border-color: #f93;
}

div.wrapper div.content-wrapper .content .form-group .input-group-addon {
  border: none;
  background: #fff;
}

div.wrapper div.content-wrapper .content .form-group .input-group-addon span.fa {
  color: #000;
  font-size: 1.6rem;
}

div.wrapper div.content-wrapper .content .form-group .input-group-addon span.fa:hover {
  color: #F7893A;
}

div.wrapper div.content-wrapper .content .form-group .btn-default {
  background: #fff;
  color: #000;
  font-weight: 400;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px 9px;
  margin-top: 0;
}

div.wrapper div.content-wrapper .content .form-group button.btn {
  margin-top: 0;
}

div.wrapper div.content-wrapper .content .form-group div.select2-container .select2-choices {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

div.wrapper div.content-wrapper .content .form-group div.select2-container .select2-choices input[type="text"] {
  border-radius: 0;
  border: none;
}

div.wrapper div.content-wrapper .content .form-group div.select2-container.select2-container-multi .select2-choices {
  background: #f5f5f5;
  border-color: transparent;
}

div.wrapper div.content-wrapper .content .form-group div.select2-container .select2-choice {
  color: #000;
  font-weight: 400;
  border: 1px solid #000;
  background: #f5f5f5;
  border-radius: 5px !important;
  box-shadow: none;
}

div.wrapper div.content-wrapper .content .form-group div.select2-container.select2-dropdown-open .select2-choice {
  border-radius: 5px 5px 0 0 !important;
  border-bottom: 1px solid #000;
}

div.wrapper div.content-wrapper .content .form-group a.sonata-toggle-filter i {
  color: #000;
}

div.wrapper div.content-wrapper .content .form-group a:hover i {
  color: #F7893A;
}

div.wrapper div.content-wrapper .content .form-group a[data-toggle="advanced-filter"] {
  color: #000;
  margin-top: 0;
}

div.wrapper div.content-wrapper .content .form-group .field-short-description {
  border: 1px solid #000;
  background: #fff;
}

div.wrapper div.content-wrapper .content .form-group .field-short-description a {
  color: #000;
  font-weight: 400;
}

div.wrapper div.content-wrapper .content .form-group .sonata-media-row .pull-left img {
  word-break: break-word;
  margin: 0 0px 15px 0;
}

div.wrapper div.content-wrapper .content .form-group .sonata-media-row .pull-left img.media-object {
  word-break: break-word;
  margin: 0 0px 15px 0;
}

div.wrapper div.content-wrapper .content .btn-group {
  border-radius: 5px !important;
}

div.wrapper div.content-wrapper .content .btn-group.open {
  border-radius: 5px 5px 0 0 !important;
}

div.wrapper div.content-wrapper .content .btn-group.open button.dropdown-toggle {
  box-shadow: none;
  border-radius: 5px 5px 0 0;
}

div.wrapper div.content-wrapper .content .btn-group button.dropdown-toggle {
  border-radius: 5px;
}

div.wrapper div.content-wrapper .content ul {
  position: relative;
}

div.wrapper div.content-wrapper .content ul.dropdown-menu {
  position: absolute;
  margin-top: 0;
  border: 1px solid black;
  padding: 5px 7px;
  border-radius: 0 0 5px 5px;
  background: #f5f5f5;
}

div.wrapper div.content-wrapper .content ul.dropdown-menu a {
  font-size: 14px;
  padding: 3px 7px 4px;
  border-radius: 5px;
}

div.wrapper div.content-wrapper .content ul.dropdown-menu a:hover {
  background: #F7893A;
  color: #000 !important;
}

div.wrapper div.content-wrapper .content ul.dropdown-menu a i {
  font-size: initial;
}

div.wrapper div.content-wrapper .content ul.sonata-tree {
  overflow: visible;
}

div.wrapper div.content-wrapper .content ul.sonata-tree li div .fa-caret-right {
  color: #000;
  font-size: 18px;
  left: -25px;
  margin-top: 0;
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  -ms-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

div.wrapper div.content-wrapper .content ul.sonata-tree li div .fa-caret-right:before {
  content: "\f105" !important;
}

div.wrapper div.content-wrapper .content ul.sonata-tree .sonata-tree__item {
  border-radius: 5px;
  padding: 5px 9px;
  background: #f5f5f5;
  border: 1px solid #fff;
}

div.wrapper div.content-wrapper .content ul.sonata-tree .sonata-tree__item.is-active {
  background: #f93;
  color: #000;
  border: 1px solid transparent;
}

div.wrapper div.content-wrapper .content ul.sonata-tree .sonata-tree__item.is-active:hover {
  background: #F7893A;
  color: #000;
}

div.wrapper div.content-wrapper .content ul.sonata-tree .sonata-tree__item.is-active a:hover {
  color: #000 !important;
}

div.wrapper div.content-wrapper .content ul.sonata-tree .sonata-tree__item:hover {
  background: rgba(0,0,0,0.05);
}

div.wrapper div.content-wrapper .content ul.sonata-tree .sonata-tree__item a {
  color: #000;
  font-weight: 400;
}

div.wrapper div.content-wrapper .content ul.sonata-tree .sonata-tree__item:after {
  display: none;
}

div.wrapper div.content-wrapper .content ul.sonata-tree .sonata-tree__item:before {
  display: none;
}

div.wrapper div.content-wrapper .content .box-body .sonata-ba-collapsed-fields a.btn.sonata-ba-action {
  background: #fff;
  border: 1px solid #000;
  color: #000;
  font-weight: 400;
  border-radius: 5px;
  margin-right: 4px;
}

div.wrapper div.content-wrapper .content .box-body .sonata-ba-collapsed-fields a.btn.sonata-ba-action:last-child {
  margin-right: 0;
}

div.wrapper div.content-wrapper .content .box-body .sonata-ba-collapsed-fields a.btn.sonata-ba-action.btn-danger {
  color: #000;
}

div.wrapper div.content-wrapper .content .box-body .sonata-ba-collapsed-fields a.btn.sonata-ba-action.btn-danger:hover {
  border-color: #000;
  color: #F7893A !important;
}

div.wrapper div.content-wrapper .content .sonata-ba-form .sonata-ba-form-actions {
  background: #fff;
  border: none;
  box-shadow: none;
}

div.wrapper div.content-wrapper .content .sonata-ba-form .sonata-ba-form-actions.well {
  padding-left: 0px;
  color: #000;
  font-weight: 400;
}

div.wrapper div.content-wrapper .content .sonata-ba-form .sonata-ba-form-actions.well.stuck {
  margin-left: 0px;
}

div.wrapper div.content-wrapper .content .sonata-ba-form .sonata-ba-form-actions button.btn {
  background: #fff;
  border: 1px solid #000;
  color: #000;
  font-weight: 400;
  border-radius: 5px;
}

div.wrapper div.content-wrapper .content .sonata-ba-form .sonata-ba-form-actions button.btn:hover {
  color: #F7893A;
}

div.wrapper div.content-wrapper .content .sonata-ba-form .sonata-ba-form-actions a.btn-danger {
  color: #000;
  background: #fff;
  border: 1px solid #000;
  font-weight: 400;
}

div.wrapper div.content-wrapper .content .sonata-ba-form .sonata-ba-form-actions a.btn-danger:hover {
  border-color: #000;
  color: #F7893A !important;
}

div.wrapper div.content-wrapper .content .sonata-ba-form .tab-content {
  padding-left: 0;
  padding-right: 0;
}

div.wrapper div.content-wrapper .content .sonata-ba-form .tab-content .box-header {
  padding-left: 0;
}

div.wrapper div.content-wrapper .content .sonata-ba-form .tab-content .box-body {
  padding-left: 0;
  padding-right: 0;
}

div.wrapper div.content-wrapper .content .nav-tabs-custom {
  background: #fff;
}

div.wrapper div.content-wrapper .content .nav-tabs-custom>.tab-content {
  background: #fff;
}

div.wrapper div.content-wrapper .content .nav-tabs-custom>.tab-content .box {
  background: #fff;
}

div.wrapper div.content-wrapper .content .nav-tabs-custom div#form_attendingSoiree label:nth-child(1n+2) {
  margin-left: 15px;
}

div.wrapper div.content-wrapper .content .box {
  background: #fff;
}

div.wrapper div.content-wrapper .content .box .box-footer {
  background: #fff;
}

div.wrapper div.content-wrapper .content .fair-select-wrapper .select2-container a {
  background: #f5f5f5;
  border-color: #000;
  border-radius: 5px !important;
}

div.wrapper div.content-wrapper .content .fair-select-wrapper .select2-container a .select2-chosen {
  color: #000;
}

div.wrapper div.content-wrapper .content .fair-select-wrapper .select2-container .select2-choice {
  box-shadow: none !important;
}

div.wrapper div.content-wrapper .text-danger {
  color: #d0362a;
}

@-webkit-keyframes alert-fade-in {
  0% {
    top: -20px;
    opacity: 0;
  }

  100% {
    top: 10px;
    opacity: 1;
  }
}

@-o-keyframes alert-fade-in {
  0% {
    top: -20px;
    opacity: 0;
  }

  100% {
    top: 10px;
    opacity: 1;
  }
}

@-ms-keyframes alert-fade-in {
  0% {
    top: -20px;
    opacity: 0;
  }

  100% {
    top: 10px;
    opacity: 1;
  }
}

@-moz-keyframes alert-fade-in {
  0% {
    top: -20px;
    opacity: 0;
  }

  100% {
    top: 10px;
    opacity: 1;
  }
}

@keyframes alert-fade-in {
  0% {
    top: -20px;
    opacity: 0;
  }

  100% {
    top: 10px;
    opacity: 1;
  }
}

