//_____________________________ checkbox square _____________________________
.checkbox, td, th{
  label{
    font-weight: $font-weight-bold;
    color:$color-primary-dark;
  }
  .icheckbox_square-blue{
    border: $border-thick solid $color-dark-alpha;
    box-sizing: border-box;
    background: $color-secondary;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition:    all .1s ease-in-out;
    -o-transition:      all .1s ease-in-out;
    -ms-transition:     all .1s ease-in-out;
    transition:         all .1s ease-in-out;
    &:hover{
      border-color: $color-primary-hover;
    }
    &.checked{
      border-color: $color-primary;
      background: $color-primary;
      &:after{
        font-family: "FontAwesome";
        content:"\f00c";
        color:$color-primary-light;
        top: 50%;
        left: 50%;
        position: absolute;
        font-size: 1.1rem;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }
    }
    ins.iCheck-helper {
      display: none;
    }
  }
  &.disabled {
    pointer-events: none;
    .icheckbox_square-blue {
      background: $color-disabled;
      border-color: $color-disabled;
    }
  }
}

#transfer-form {
  .col-md-12{
    ul{
      &.nav{
        li{
          .icheckbox_square-blue{
            border: 1px solid $color-badge-hover;
            border-radius: 5px;
            background: $color-badge-hover;
            &:hover{
              border-color: $color-primary-hover;
            }
            &.checked{
              background: $color-primary;
              border-color: $color-primary-light;
              &::after{
                opacity: 1;
              }
            }
            &:after{
              position: absolute;
              top:50%;
              left: 67%;
              width: 100%;
              height: 100%;
              font-size: 14px;
              background: transparent;
              font-family: "FontAwesome";
              transform: translate(-50%, -50%);
              content: "\f00c";
              color:white;
              opacity: 0;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

#transfer-form {
  .col-md-12{
    .select-all-wrapper{
      margin-right: 10px;
      .icheckbox_square-blue{
        margin-left: 10px;
        border: 1px solid $color-badge-hover;
        border-radius: 5px;
        background: $color-badge-hover;
        &:hover{
          border-color: $color-primary-hover;
        }
        &.checked{
          background: $color-primary;
          border-color: $color-primary-light;
          &::after{
            opacity: 1;
          }
        }
        &:after{
          position: absolute;
          top:50%;
          left: 67%;
          width: 100%;
          height: 100%;
          font-size: 14px;
          background: transparent;
          font-family: "FontAwesome";
          transform: translate(-50%, -50%);
          content: "\f00c";
          color:white;
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
}




//_____________________________ checkbox circle _____________________________
.iradio_square-blue{
  border-radius: 50px;
  border: 1px solid $color-dark-alpha;
  background: $color-primary-light;
  &:hover{
    border: 1px solid $color-primary;
    background: none;
  }
  &.checked{
    background:$color-primary !important;
    border: 1px solid $color-primary;
    &:after{
      font-family: "FontAwesome";
      content:"\f00c";
      color:$color-primary-light;
      top: 50%;
      left: 50%;
      position: absolute;
      font-size: 1.1rem;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }
}
