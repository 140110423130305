.wrapper aside.main-sidebar{
  padding-top: 80px;
  background: $color-secondary;
  box-shadow: 0 3px 10px rgba(0,0,0,0.16);
  width: 200px;
  display: table;
  /*-webkit-transition: transform .2s ease-in-out, width .2s ease-in-out;
  -moz-transition: transform .2s ease-in-out, width .2s ease-in-out;
  -ms-transition: transform .2s ease-in-out, width .2s ease-in-out;
  -o-transition: transform .2s ease-in-out, width .2s ease-in-out;
  transition: transform .2s ease-in-out, width .2s ease-in-out;
  &:hover{
    width: 200px;
    ul{
      li.treeview {
        a{
          span:nth-child(2) {
            display: block;
            margin-top: 0px;
            opacity: 1;
          }
        }
      }
    }
  }*/
  form {
    border-radius: 0;
    border: none;
    .input-group{
      input[type="text"],.btn{
        background: $color-secondary-light;
        border-bottom: 1px solid transparent;
        color:$color-primary-dark;
        padding-left: 5px;
      }
    }
  }
  ul {
    li.treeview{
      font-size: $font-size-side-bar;
      a{
        color:$color-primary-dark;
        text-align: center;
        padding: 20px 5px 20px 5px;
        border-left: 0;
        i{
          width: 100%;
          display: block;
          text-align: center;
          font-size: $font-size-side-bar-icon;
          margin-bottom: 8px;
        }
        span:nth-child(2){
          display: block;
          /*margin-top: -20px;*/
          margin-top: 0px;
          opacity: 1;
          -webkit-transition: opacity .2s ease-in-out, margin-top .2s ease-in-out;
          -moz-transition:    opacity .2s ease-in-out, margin-top .2s ease-in-out;
          -o-transition:      opacity .2s ease-in-out, margin-top .2s ease-in-out;
          -ms-transition:     opacity .2s ease-in-out, margin-top .2s ease-in-out;
          transition:         opacity .2s ease-in-out, margin-top .2s ease-in-out;
        }
        .pull-right-container{
          display: none;
        }
      }
      &:hover{
        &>a{
          /*color:$color-primary-light !important;
          background: $color-secondary;*/
          background: $color-secondary-light;
          color:$color-primary-dark;
        }
      }
      ul.treeview-menu{
        margin: 0;
        padding-left: 0;
        background: $color-primary-light;
        position: relative;
        overflow: hidden;
        a{
          font-weight: $font-weight-bold;
          padding: 15px 5px 15px 5px;
        }
        &:after{
          position: absolute;
          content:"";
          width: 100%;
          height: 2px;
          bottom:-2px;
          left:0%;
          background: $color-primary-light;
          box-shadow: 0px 0px 6px rgba(0,0,0,0.2);
          /*background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 50%, rgba(255,255,255,0) 100%);
          background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 50%,rgba(255,255,255,0) 100%);
          background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 50%,rgba(255,255,255,0) 100%);*/
        }
        &:before{
          position: absolute;
          content:"";
          width: 100%;
          height: 2px;
          top:-3px;
          left:0%;
          background: transparent;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
          z-index: 1;
        }
        i{
          display: none;
        }
      }
      &.active {
        &>a {
          /*background: $color-secondary;
          color:white;*/
          background: $color-secondary-light;
          color:$color-primary-dark;
        }
        ul.treeview-menu{
          li{
            &:hover{
              a{
                color:$color-primary-hover !important;
              }
            }
            a{
              background: $color-primary-light;
            }
            &.active{
              a{
                color:$color-primary !important;
              }
            }
          }
        }
      }
    }
  }
  p{
    border-top:0 !important;
  }
}