.modal, .ui-dialog{
  &.ui-widget{
    &.ui-widget-content{
      border:none !important;
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15) !important;
      border-radius: 0 !important;
      background: $color-primary-light;
      .ui-tooltip.ui-corner-all{
        left: calc(100% - 50px) !important;
      }
      .sonata-ba-form-actions{
        border-color:$color-primary-dark;
      }
    }
  }
  .ui-dialog-titlebar{
    background: transparent !important;
    border:none !important;
    padding-bottom: 0 !important;
    .ui-dialog-titlebar-close{
      background: none !important;
      border: none !important;
      &:active{
        .ui-icon-closethick{
          filter: invert(0) !important;
        }
      }
      .ui-icon-closethick{
        filter: invert(1) !important;
      }
    }
  }
  .modal-header, .ui-dialog-titlebar{
    padding-left: 25px;
    border-bottom: 1px solid $color-primary-dark;
    button{
      &.close{
        color:$color-primary-dark;
        opacity: 0.6;
        &:hover{
          opacity: 1;
        }
      }
    }
  }
  .modal-title{
    color:$color-primary-dark;
    font-weight: $font-weight-bold;
  }
  .ui-dialog-content{
    padding-top: 10px !important;
  }
  .modal-body, .ui-dialog-content{
    padding: 25px !important;
    div.btn-group{
      a{
        /*border: none;
        background: transparent;
        box-shadow: none;
        color:$color-primary-dark;
        font-weight: $font-weight-bold;
        border-radius:5px !important;
        margin-top: 0px;
        &.active{
          border: $border-thick solid $color-primary-dark;
          color:$color-primary-dark;
        }
        i{
          font-size: 2rem;
          color: $color-primary-dark;
          padding-top: 2px;
          margin: 0 7px;
          -webkit-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -moz-transition:    opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -o-transition:      opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -ms-transition:     opacity .1s ease-in-out, margin-top .3s ease-in-out;
          transition:         opacity .1s ease-in-out, margin-top .3s ease-in-out;
        }
        &:hover{
          color: $color-primary-hover;
          i{
            color: $color-primary-hover;
          }
        }*/
        border: none;
        background: transparent;
        box-shadow: none;
        color:$color-primary-dark;
        font-weight: $font-weight-bold;
        border-radius:5px !important;
        margin: 0;
        padding: 0;
        &.btn-success{
          padding: 5px 10px;
          i{
            margin-left: 0;
            font-size: inherit;
          }
        }
        &.active{
          /*border: $border-thick solid $color-primary-dark;*/
          border:none;
          display: none;
          i{
            color:$color-primary;
          }
        }
        i{
          font-size: 2rem;
          color: $color-primary-dark;
          padding: 0;
          margin: 0 0 0 25px;
          display: initial;
          -webkit-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -moz-transition:    opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -o-transition:      opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -ms-transition:     opacity .1s ease-in-out, margin-top .3s ease-in-out;
          transition:         opacity .1s ease-in-out, margin-top .3s ease-in-out;
        }
        &:hover{
          color: $color-primary-hover;
          i{
            color: $color-primary-hover;
          }
        }
      }
    }
    .sonata-filter-form{
      .form-group{
        text-align: right;
        margin-right: -10px;
      }
    }
    .form-group{
      .btn-default{
        background: $color-primary-light;
        color: $color-primary-dark;
        font-weight: $font-weight-bold;
        border: $border-thick solid $color-primary-dark;
        border-radius: 5px;
        padding: 5px 9px;
        margin-top: 0;
      }
      button{
        &.btn{
          margin-top: 0;
          border: $border-thick solid $color-primary-dark;
          padding: 5px 9px;
        }
      }
      a{
        &[data-toggle="advanced-filter"]{
          color:$color-primary-dark;
        }
      }
    }
    .form-actions{
      border:none;
      background: $color-primary-light;
      box-shadow: none;
      padding-left: 20px;
      border-top: 1px solid black;
      border-radius: 0;
      button[type="submit"]{
        background: $color-primary-light;
        border:$border-thick solid $color-primary-dark;
        border-radius: 5px;
        font-weight: $font-weight-bold;
        color:$color-primary-dark;
        &:hover{
          color:$color-primary-hover;
        }
      }
    }
    .box{
      background: $color-primary-light;
      &.box-primary{
        .box-header {
          background: transparent ;
          color: $color-primary-dark !important;
          padding-left: 0;
          .box-title{
            font-weight: 500 !important;
          }
        }
        .box-body{
          padding-left: 0;
        }
      }
      .box-header{
        .box-title{
          color: $color-primary-dark;
        }
      }
      &.box-success{
        border-top-color:$color-primary;
        .box-body{
          display: flex;
          margin: 20px 0;
          div{
            margin: auto;
          }
        }
      }
    }
    .btn{
      &.btn-app{
        background: $color-secondary;
        color: $color-primary-dark;
      }
    }
    textarea{
      &.form-control{
        background: $color-secondary-light;
        color: $color-primary-dark;
        &:focus, &:active{
          border-color:$color-primary-dark;
        }
      }
    }
    .nav-tabs-custom{
      .tab-content{
        padding-left:0;
        .list-unstyled {
          label {
            color:$color-primary-dark !important;
          }
        }
      }
    }
  }
  .modal-content{
    background: $color-primary-light;
    form{
      .box-header{
        text-align: left;
      }
    }
  }
  ul.nav {
    /*li{
      a {
        color: $color-primary-dark;
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
      }
      &>a{
        border: $border-thick solid transparent;
        padding: 4px 7px;
        border-radius: 5px;
      }
      &.open>a{
        &:focus{
          background-color: transparent;
        }
        background: transparent;
        border: $border-thick solid $color-primary-dark;
      }
      .badge{
        background: transparent;
        color: $color-primary-dark;
        border: $border-thick solid $color-primary-dark;
        padding: 5px 8px;
        border-radius: 15px;
      }*/
    &>li{
      &.dropdown{
        a.dropdown-toggle{
          float: right;
          display: -webkit-inline-box;
          background-color: transparent;
        }
        &.open{
          i{
            color:$color-primary-dark;
          }
        }
      }
      &.dropdown{
        /*margin-right: 20px;*/
        margin-top: -1px;
      }
      a {
        color: $color-primary-dark;
        font-size: 0;
        font-weight: $font-weight-bold;
        i{
          font-size: 2.4rem;
        }
      }
      &>a{
        /*border: $border-thick solid transparent;*/
        border: none;
        border-radius: 5px;
        display: initial;
        padding: 0;
        i{
          line-height: inherit;
          &.fa-plus-circle:before{
            font-family: "FontAwesome";
            content:"\f067";
            color:$color-primary-dark;
          }
        }

      }
      .dropdown-menu{
        border:1px solid black;
        padding: 5px 7px;
        clear: both;
        background: $color-secondary-light;
        &.ui-sortable{
          max-height: 50vh;
          overflow-y: scroll;
          li:last-child{
            margin: 15px 0 10px 0;
            a{
              background: $color-primary;
              border:none;
              border-radius: 5px;
              color: $color-primary-dark;
              font-weight: 500;
            }
          }
        }
        a{
          font-size: 14px;
          padding: 3px 7px 4px;
          border-radius: 5px;
          &:hover{
            background: $color-primary-hover;
            color:$color-primary-dark !important;
          }
          &:focus{
            background: transparent !important;
          }
          i{
            font-size: initial;
          }
        }
      }
      /* &.open>a{
         background: transparent;
         border: $border-thick solid $color-primary-dark;
       }*/
      &:hover{
        &>a {
          color:$color-primary-hover !important;
          &>i{
            color:$color-primary-hover !important;
          }
          .badge {
            color: $color-primary-hover;
            &:after, &:before {
              color: $color-primary-hover !important;
            }
          }
        }
      }
      .badge{
        position: relative;
        background: transparent;
        color: $color-primary;
        /*border: $border-thick solid $color-primary-dark;*/
        border:none;
        padding: 5px 8px;
        border-radius: 15px;
        display: table-cell;
        -webkit-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
        -moz-transition:    opacity .1s ease-in-out, margin-top .3s ease-in-out;
        -o-transition:      opacity .1s ease-in-out, margin-top .3s ease-in-out;
        -ms-transition:     opacity .1s ease-in-out, margin-top .3s ease-in-out;
        transition:         opacity .1s ease-in-out, margin-top .3s ease-in-out;
        &:after{
          position: absolute;
          top:48%;
          left: 10%;
          transform: translate(0, -50%);
          color: $color-primary-dark;
          content: '(';
        }
        &:before{
          position: absolute;
          top:48%;
          right: 10%;
          transform: translate(0, -50%);
          color: $color-primary-dark;
          content: ')';
        }
      }
      b.caret{
        display: none;
      }
    }
    &.nav-tabs{
      padding-left: 20px;
      border:none;
      li{
        border:none;
        margin: 0;
        &:first-child {
          a {
            border-radius: 5px 0 0 5px;
          }
        }
        &:last-child {
          a {
            border-radius: 0px 5px 5px 0px;
          }
        }
        &:only-child {
          a{
            border-radius: 5px;
          }
        }
        &.active{
          a {
            background: $color-secondary-hover;
            color: $color-primary-dark;
          }
        }
        a{
          border:none;
          color:$color-primary-dark;
          background: $color-secondary-light;
          font-size: 1.5rem;
          padding: 7px 12px;
          i{
            &.has-errors{
              font-size: inherit;
            }
          }
          &:hover{
            color:$color-primary-light !important;
            background: $color-secondary-hover;
          }
        }
      }
    }
    .tab-content{
      padding-left: 0;
      padding-right: 0;
      .box-header{
        padding-left: 0;
      }
      .box-body{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  ul{
    position: relative;
    &.sonata-tree {
      overflow: visible;
      li{
        div {
          .fa-caret-right{
            color: $color-primary-dark;
            font-size: 24px;
            left: -25px;
            -webkit-transition: all .25s ease-in-out;
            -moz-transition:    all .25s ease-in-out;
            -o-transition:      all .25s ease-in-out;
            -ms-transition:     all .25s ease-in-out;
            transition:         all .25s ease-in-out;
            &:before{
              content: "\f105" !important;
            }
          }
        }
      }
      .sonata-tree__item {
        border-radius: 5px;
        padding: 5px 9px;
        background: $color-secondary-light;
        /*border: none;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);*/
        border: 1px solid $color-primary-dark;
        &.is-active{
          background: $color-primary;
          color:$color-primary-dark;
          border: 1px solid transparent;
          &:hover{
            background: $color-primary-hover;
            color:$color-primary-dark;
          }
          a{
            &:hover{
              color:$color-primary-dark !important;
            }
          }
        }
        &:hover{
          background: rgba(0,0,0,0.05);
        }
        a {
          color: $color-primary-dark;
          font-weight: $font-weight-bold;
        }
        &:after {
          display: none;
        }
        &:before{
          display: none;
        }
      }
    }
  }
  .form-group, form{
    margin-bottom: 20px;
    input[type="text"], input[type="url"], input[type="email"], input[type="password"]{
      background: $color-secondary-light;
      border:none;
      border-bottom: $border-thick solid transparent;
      color:$color-primary-dark;
      &:focus{
        border-color: $color-primary;
      }
    }
    input[type="number"]{
      background: $color-secondary-light;
      box-shadow: none;
      border:none;
      border-bottom: $border-thick solid transparent;
      color:$color-primary-dark;
    }
    .btn-default {
      background: $color-primary-light;
      color: $color-primary-dark;
      font-weight: $font-weight-bold;
      border: $border-thick solid $color-primary-dark;
      border-radius: 5px;
      padding: 5px 9px;
      margin-top: 0;
    }
    .btn-group, .form-actions{
      padding-left: 0px !important;
      a{
        &.btn{
          background: $color-primary-light;
          border:$border-thick solid $color-primary-dark;
          border-radius: 5px;
          font-weight: $font-weight-bold;
          border-radius: 5px !important;
          margin-right: 4px;
          &:first-child:not(:last-child):not(.dropdown-toggle){
            border-radius: 5px;
            margin-right: 4px;
          }
          &.btn-danger{
            color:red;
            &:hover{
              color:red !important;
              border-color: red;
              i{
                color:red !important;
              }
            }
          }
        }
      }
    }
    button {
      &.btn, &.btn.btn-primary {
        margin-top: 0;
        padding: 5px 9px;
      }
    }
    div.select2-container {
      .select2-choice {
        color: $color-primary-dark;
        font-weight: $font-weight-bold;
        border: $border-thick solid $color-primary-dark;
        border-radius: 5px !important;
        box-shadow: none;
        background: $color-secondary-light;
      }
      &.select2-dropdown-open .select2-choice{
        border-radius: 5px 5px 0 0 !important;
        border-bottom: 1px solid $color-primary-dark;
        background: $color-secondary-light;
      }
    }
    a {
      &.sonata-toggle-filter{
        i{
          color:$color-primary-dark;
        }
      }
      &:hover{
        i{
          color:$color-primary-hover;
        }
      }
      &[data-toggle="advanced-filter"] {
        color: $color-primary-dark;
        margin-top: 0;
      }
    }
    .field-short-description{
      border: $border-thick solid black;
      a{
        color:$color-secondary;
        font-weight: $font-weight-bold;
      }
    }
  }
  .navbar{
    .container-fluid{
      padding-right: 0;
    }
  }
  .nav-tabs-custom{
    background: $color-primary-light ;
    &>.tab-content{
      background: $color-primary-light ;
    }
  }
  .modal-footer{
    button{
      &.btn{
        margin-top: 0 !important;
      }
    }
    a.btn.btn-primary{
      margin-top: 0 !important;
    }
  }
}
