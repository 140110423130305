.pagination{
  li{
    border:none;
    background: transparent;
    &:first-child{
      a{
        border-radius: 5px 0 0 5px;
      }
    }
    &:last-child{
      a{
        border-radius: 0px 5px 5px 0 ;
      }
    }
    a{
      border: none;
      background: $color-secondary-light;
      color: $color-primary-dark;
      font-weight: 500;
    }
    &.active{
      a{
        background: $color-primary;
        &:hover{
          cursor: pointer;
        }
        &:hover, &:focus{
          background: $color-primary-hover;
          color: $color-primary-light !important;
        }
      }
    }
  }
}