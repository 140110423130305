div.wrapper {
  div.content-wrapper{
    .content{
      .nav-tabs{
        border:none;
        li{
          border:none;
          margin: 0;
          &:first-child {
            a {
              border-radius: 5px 0 0 5px;
            }
          }
          &:last-child {
            a {
              border-radius: 0px 5px 5px 0px;
            }
          }
          &.active{
            a {
              background: $color-secondary-hover;
              color: $color-primary-dark;
            }
          }
          &:only-child {
            a{
              border-radius: 5px;
            }
          }
          a{
            border:none;
            color:$color-primary-dark;
            background: $color-secondary-light;
            i{
              &.has-errors{
                font-size: inherit;
              }
            }
            &:hover{
              color:$color-primary-light !important;
              background: $color-secondary-hover;
            }
          }
        }
      }
    }
  }
}

