//_____________________________ link in general _____________________________
a{ -webkit-transition: all .1s ease-in-out;
  -moz-transition:    all .1s ease-in-out;
  -o-transition:      all .1s ease-in-out;
  -ms-transition:     all .1s ease-in-out;
  transition:         all .1s ease-in-out;
  &:hover {
    color: $color-primary-hover !important;
    text-decoration: none !important;
  }
}




//_____________________________ hover widget overlay _____________________________
.ui-widget-shadow{
  background: $color-secondary-light;
  color: $color-primary-dark;
  opacity: 1;
  border-radius: 5px;
  border:none !important;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.1) !important;
}




//_____________________________ link focus _____________________________
:focus{
  outline:transparent solid 0px !important;
}




//_____________________________ selection _____________________________
::selection{
  background: $color-primary;
  color: $color-primary-dark;
}




//_____________________________ button _____________________________
button.btn.btn-primary {
  &:active:focus, &:active:hover{
    color: $color-primary-hover;
    background-color: rgba(0,0,0,0.05);
    border-color: black;
  }
}




//_____________________________ alert banner _____________________________
.alert.alert-success{
  border:none;
  background-color: $color-primary !important;
  border-color: $color-primary !important;
}

.alert.alert-danger{
  background-color: $color-primary !important;
  border-color: $color-primary !important;
}

.alert.alert-info {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
  color: $color-primary-dark !important;
}



//_____________________________ plus icon _____________________________
.fa-plus-circle{
  &:before{
    content:"\f067";
  }
}




//_____________________________ label _____________________________
.label{
  padding: 0.4em 0.6em 0.5em;
}

.label-success {
  background-color: $color-label-success !important;
}

.label-danger{
  background-color: $color-label-danger !important;
}
