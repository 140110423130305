//_____________________________ search results _____________________________
.sonata-search-result-show{
  text-align: left;
  color:$color-primary-dark;
  margin-bottom: 15px;
  .box {
    box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
    .box-header {
      padding-left: 15px;
      i{
        vertical-align: middle;
      }
      .box-title {
        font-size: 14px;
      }
      .box-tools {
        a{
          color:$color-primary-dark;
          font-size: 18px;
          &:hover{
            background: transparent;
          }
        }
        .badge{
          position: relative;
          background: transparent;
          color:$color-primary;
          font-size: 18px;
          line-height: initial;
          margin-top: -2px;
          &:before{
            position: absolute;
            content:"(";
            color:$color-primary-dark;
            top: 2px;
            left: 0;
          }
          &:after{
            position: absolute;
            content:")";
            color:$color-primary-dark;
            top: 2px;
            right: 0;
          }
        }
      }
    }

    .box-body {
      a {
        border-left: 0;
      }
    }
  }
  .sonata-search-result-list{
    li{
      a{
        color:$color-primary-dark;
      }
      &> a{
        &:hover{
          background: $color-secondary;
        }
      }
    }
  }
}




//_____________________________ search choice tag _____________________________
.select2-container-multi{
  .select2-choices {
    li {
      &.select2-search-choice {
        color: $color-primary-light;
        font-weight: 500;
        border: none;
        border-radius: 5px;
        padding: 6px 8px 6px 18px;
        background: $color-primary-dark;
        margin-bottom: 7px;
        a {
          &.select2-search-choice-close {
            transition: all 0s;
            background: none;
          }
          &:after{
            font-family: "FontAwesome";
            content:"\f00d";
            color:$color-primary-light;
            top: 50%;
            left: 50%;
            position: absolute;
            font-size: 1.1rem;
            transform: translate(-50%, -50%);
            pointer-events: none;
            opacity: 0.7;
          }
          &:hover{
            &:after{
              opacity: 1;
              color:$color-primary-hover;
            }
          }
        }
      }
    }
  }
}




//_____________________________ search results headline _____________________________
/*
.page-header{
  position: relative;
  &:after{
    position: absolute;
    content:"";
    top:0;
    left: 155px;
    background: $color-primary;
    width: 65%;
    height: 100%;
    mix-blend-mode: screen;
  }
}*/
