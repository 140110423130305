.bootstrap-datetimepicker-widget{
  background: $color-secondary-light;
  border:none;
  box-shadow:2px 3px 10px rgba(0,0,0,0.2);
}

.datepicker {
  table thead tr th {
    color:$color-primary-dark;
    &:hover{
      background: $color-primary-light !important;
    }
  }
  table tr td {
    text-shadow: none;
    color: $color-primary-dark;
    &.active {
      &.active {
        background: $color-primary;
        color: $color-primary-dark;
        text-shadow: none;
      }

      &:hover {
        &.active {
          background: $color-primary-hover;
        }
      }
      &.disabled.disabled{
        background: $color-primary;
      }
    }

    &.today {
      /*background: $color-primary;*/
      background: linear-gradient(135deg, rgba(255, 107, 0, 1) 0%, rgba(255, 171, 66, 1) 95%);
      color: $color-primary-dark;
      text-shadow: none;

      &:before {
        border-bottom: 7px solid white;
      }

      &:hover {
        color: $color-primary-dark !important;
        background: $color-primary-hover !important;
        background-color: $color-primary-hover !important;
      }
    }

    &.day {
      &:hover {
        color: $color-primary-dark !important;
        background: $color-primary-hover !important;
        background-color: $color-primary-hover !important;
      }
    }
  }
}
.timepicker{
  .timepicker-picker {
    a {
      color: $color-primary-dark;
      &:hover{
        span{
          background: $color-primary-light !important;
        }
      }
    }
  }
  .timepicker-hours, .timepicker-minutes, .timepicker-seconds{
    table{
      tbody{
        tr{
          td{
            &:hover{
              background: $color-primary-light !important;
            }
          }
        }
      }
    }
  }
}
.bootstrap-datetimepicker-widget{
  .picker-switch{
    a{
      color:$color-primary-dark;
      &:hover{
        span{
          background: $color-primary-light !important;
        }
      }
    }
  }
}