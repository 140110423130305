@import "abstracts/variables";      // Colors, Sizes, etc.
@import "abstracts/mixins";          // Typography Styles

@import "base/typography";          // Typography Styles

@import "components/base";          // Base Components
@import "components/checkbox";      // Checkboxes
@import "components/collapsible";   // Collapsibles
@import "components/datepicker";    // Datepicker
@import "components/modal";         // Modal Overlay
@import "components/pagination";    // Pagination Buttons
@import "components/popover";       // Popover
@import "components/search";        // Search Bar & Results
@import "components/tabs";          // Content Tabs

@import "layout/header";            // Page Header
@import "layout/inputs";            // Input Fields
@import "layout/loginpanel";        // Login Field Start Page
@import "layout/sidemenu";          // Side Menu
@import "layout/tables";            // Tables

body{
  color:$color-primary-dark;
  &.sonata-bc{
    div.wrapper{
      background-color: $color-primary-light;
      header {
        margin-bottom: 0px;
        .alert {
          &.alert-warning {
            position: fixed;
            width: 60%;
            z-index: 10000;
            left: 20%;
            color: $color-primary-light !important;
            top: -20px;
            opacity: 0;
            padding: 30px 15px;
            background: $color-label-danger !important;
            box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.3);
            animation-name: alert-fade-in;
            animation-duration: 400ms;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-delay: 800ms;
            -webkit-animation-name: alert-fade-in;
            -webkit-animation-duration: 400ms;
            -webkit-animation-iteration-count: 1;
            -webkit-animation-fill-mode: forwards;
            -webkit-animation-delay: 800ms;
            -ms-animation-name: alert-fade-in;
            -ms-animation-duration: 400ms;
            -ms-animation-iteration-count: 1;
            -ms-animation-fill-mode: forwards;
            -ms-animation-delay: 800ms;
            -moz-animation-name: alert-fade-in;
            -moz-animation-duration: 400ms;
            -moz-animation-iteration-count: 1;
            -moz-animation-fill-mode: forwards;
            -moz-animation-delay: 800ms;
            -o-animation-name: alert-fade-in;
            -o-animation-duration: 400ms;
            -o-animation-iteration-count: 1;
            -o-animation-fill-mode: forwards;
            -o-animation-delay: 800ms;
          }
          h4{
            padding: 0 30px 0 20px;
            line-height: 1.5;
          }
          &.alert-dismissable {
            .close{
              right: 20px;
              font-size: 28px;
              color: $color-primary-light;
              position: absolute;
              opacity: 0.8;
              line-height: 1;
              top: 50%;
              transform: translate(0px, -60%);
              &:hover{
                opacity: 1;
              }
            }
          }
        }
      }
      .content {
        form {
          .form-group {
            input[type="text"], input[type="number"], input[type="url"], input[type="email"], input[type="password"] {
              border-bottom: 1px solid $color-primary-dark;
              background: $color-secondary-light;
            }

            div.select2-container {
              .select2-choices {
                input[type="text"] {
                  background: $color-secondary-light;
                }
              }
            }
          }
        }
      }
    }
    div.wrapper, .modal, .ui-dialog{
      .box{
        box-shadow: none;
      }
      .box.box-primary {
        border: none;
        box-shadow: none;
        .box-header, .modal-header, .ui-dialog-titlebar {
          .box-title{
            font-weight: 500;
            color:$color-primary-dark;
          }
          .btn-group{
            margin-left: 20px;
            border:$border-thick solid $color-primary-dark;
            box-shadow: none;
            border-radius: 5px;
            .btn {
              background: $color-primary-light;
              border:none;
              .text-info {
                color: $color-primary-dark;
              }
              &:hover{
                background: $color-primary-light;
                color: $color-primary-hover;
                .text-info {
                  color: $color-primary-hover;
                }
              }
            }
            .dropdown-menu{
              margin-left: -1px;
              a{
                color:$color-primary-dark;
              }
            }
          }
        }
        .box-content{
          .sonata-tree__item{
            color:$color-primary-dark;
            /*border:none;
            box-shadow: 3px 3px 8px rgba(0,0,0,0.1);*/
            border: 1px solid black;
            padding: 12px;
            a{
              margin-right: 10px;
            }
            &:hover{
              background: $color-secondary-light;
            }
            span.label{
              border:none;
              background-color: $color-primary !important;
              color:$color-primary-dark !important;
              font-weight: $font-weight-bold;
            }
          }
          a{
            color: $color-primary-dark;
            font-weight: $font-weight-bold;
          }
        }
        .box-body{
          .info-box{
            box-shadow: none;
            background: transparent;
            .info-box-icon {
              position: relative;
              background: $color-secondary-light !important;
              border: none;
              border-radius: 10px;
              i {
                position: absolute;
                color: $color-primary-dark;
                font-size: 36px;
                top: 50%;
                left: 50%;
                transform: translate(-46%, -50%);
                &:before{
                  font-family: "FontAwesome";
                  content:"\f061";
                }
              }
            }
            .progress{
              background: black;
              margin-left: 0px;
            }
            .progress-description{
              ul{
                li{
                  a{
                    color: $color-primary-dark;
                  }
                }
              }
            }
            .info-box-text {
              color: $color-primary-dark;
              font-weight: $font-weight-bold;
            }
          }
        }
        .box-footer {
          margin-top: 40px;
        }
      }
      .box{
        .box-header, .modal-header, .ui-dialog-titlebar {
          .box-title{
            color:$color-primary-dark;
          }
        }
      }

      .navbar {
        border: none;
        background: $color-primary-light;

        .navbar-collapse {
          border: none;
          background: $color-primary-light;
          padding-left: 0;
          padding-right: 0;
          .navbar-right{
            margin-right: 0;
            li{
              margin-left: 25px;
            }
          }
        }
        &.stuck{
          margin-left: -80px;
          .navbar-collapse {
            /*padding-right: 80px;*/
          }
          .container-fluid{
            margin-right: 80px;
            margin-left: 80px;
          }
        }
        .navbar-collapse {
          .navbar-left {
            ul {
              &.nav{
                &>li {
                  &>a {
                    background: $color-secondary;
                    border: 1px solid $color-primary-dark;
                    color: $color-primary-dark;
                    border-radius: 5px;
                    padding: 6px 12px;

                    i {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .alert{
        &.alert-warning {
          box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
          background: $color-primary !important;
          border: none;
          color: $color-primary-dark !important;
          font-weight: $font-weight-bold;
        }
        .close {
          text-shadow: none;
          &:hover {
            opacity: 1;
          }
        }
      }

      .sonata-ba-content{
        .box-success{
          border-top:3px solid $color-primary;
          box-shadow: none;
          border-right: none;
          .box-title{
            color: $color-primary-dark;
            font-weight: $font-weight-bold;
          }
          .box-body{
            display: flex;
            margin: 20px 0;
            div{
              margin: auto;
              a{
                color:$color-primary-dark;
                font-weight: $font-weight-bold;
              }
            }
            div:nth-child(1){
              .btn-app:after{
                position: absolute;
                background: transparent;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 58%;
                mix-blend-mode: screen;
                -webkit-transition: all 0.1s ease-in-out;
                -moz-transition: all 0.1s ease-in-out;
                -ms-transition: all 0.1s ease-in-out;
                -o-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out;
              }
              .btn-app:hover:after{
                /*background: $color-primary-hover;*/
              }
            }
          }
          .btn-app{
            background: $color-secondary;
            border: 1px solid $color-primary-dark;
            /*box-shadow: 3px 3px 10px rgba(0,0,0,0.15);*/
          }
        }
      }
      .revision-detail{
        .box-primary{
          border-radius:0;
          border-top: 2px solid $color-primary;
          margin: 0px 0 40px 0;
          .box-header{
            text-align: left;
            padding-left: 0;
            font-weight: 500;
          }
        }
      }
      .nav-tabs-custom{
        box-shadow: none;
        background: $color-primary-light !important;
        &>.tab-content{
          background: $color-primary-light;
        }
      }
    }
  }
  ul.select2-results {
    color: $color-primary-dark;
    background: $color-primary-light;
    border-radius: 5px;
    padding: 5px 7px;
    margin: 4px 4px 4px 4px;
    .select2-highlighted {
      color: $color-primary-dark;
      background: $color-primary-hover;
      border-radius: 5px;
    }
    .select2-no-results{
      background: $color-secondary-light;
      color:$color-primary-dark;
      border-radius: 5px;
    }
    .select2-searching{
      background: $color-secondary-light !important;
      color: $color-primary-dark;
      border-radius: 5px;
    }
  }
  div.select2-search{
    box-shadow: none;
    input{
      box-shadow: none;
      border: none;
      background: $color-primary-light;
      color:$color-primary-dark;
      border-bottom: $border-thick solid $color-primary-dark;
      border-radius:0;
      &.select2-active{
        background: #e1e0dd url(/admin/bundles/sonatacore/vendor/select2/select2-spinner.gif) no-repeat 100%;
        background: url(/admin/bundles/sonatacore/vendor/select2/select2-spinner.gif) no-repeat 100%, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #e1e0dd ), color-stop(0.99, #e1e0dd ));
        background: url(/admin/bundles/sonatacore/vendor/select2/select2-spinner.gif) no-repeat 100%, -webkit-linear-gradient(center bottom, #e1e0dd   85%, #e1e0dd  99%);
        background: url(/admin/bundles/sonatacore/vendor/select2/select2-spinner.gif) no-repeat 100%, -moz-linear-gradient(center bottom, #e1e0dd  85%, #e1e0dd  99%);
        background: url(/admin/bundles/sonatacore/vendor/select2/select2-spinner.gif) no-repeat 100%, linear-gradient(to bottom, #e1e0dd  85%, #e1e0dd  99%) 0 0;
        border-bottom: $border-thick solid $color-primary-dark;
        filter: invert(1);
        color: $color-primary-light;
        &::after{
          position: absolute;
          top:0;
          left: 0;
          width: 106%;
          height: 106%;
          content: "";
          background: red;
        }
      }
    }
  }
  .select2-drop{
    box-shadow: none;
    border:$border-thick solid $color-primary-dark;
    border-top: 1px solid $color-primary-dark;
    border-radius: 0 0 5px 5px !important;
    background: $color-primary-light;
  }
  button.btn.btn-primary, a.btn.btn-primary {
    font-weight: $font-weight-bold;
    background: $color-primary-light;
    color: $color-primary-dark;
    border: $border-thick solid $color-primary-dark;
    border-radius: 5px;
    margin-top: 10px;
    &:hover{
      background: $color-primary-hover !important;;
      color:white !important;
    }
  }
}




//_____________________________ content head area _____________________________
div.wrapper div.content-wrapper{
  background: $color-primary-light;
  margin-left: 200px;
  padding-top: 160px !important;
  .content-header{
    padding: 15px 80px 20px 80px;
    nav {
      .container-fluid{
        border-bottom: 3px solid $color-primary;
        padding: 20px 0 10px 0;
        background: $color-primary-light;
        a{
          &.navbar-brand{
            font-weight: $font-weight-bold;
            color: $color-primary-dark;
          }
        }
      }
    }
    div.btn-group{
      a{
        border: none;
        background: transparent;
        box-shadow: none;
        color:$color-primary-dark;
        font-weight: $font-weight-bold;
        border-radius:5px !important;
        margin: 0;
        padding: 0;
        &.active{
          /*border: $border-thick solid $color-primary-dark;*/
          border:none;
          display: none;
          i{
            color:$color-primary;
          }
        }
        i{
          font-size: 2rem;
          color: $color-primary-dark;
          padding: 0;
          margin: 0 0 0 25px;
          display: initial;
          -webkit-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -moz-transition:    opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -o-transition:      opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -ms-transition:     opacity .1s ease-in-out, margin-top .3s ease-in-out;
          transition:         opacity .1s ease-in-out, margin-top .3s ease-in-out;
        }
        &:hover{
          color: $color-primary-hover;
          i{
            color: $color-primary-hover;
          }
        }
      }
    }
    .navbar-left{
      ul.nav {
        &>li{
          padding: 15px 15px;
          a{
            font-size: 16px;
          }
        }
      }
    }
    ul.nav {
      &>li{
        &.dropdown{
          /*margin-right: 20px;*/
          margin-top: -1px;
        }
        a {
          color: $color-primary-dark;
          font-size: 0;
          font-weight: $font-weight-bold;
          i{
            font-size: 2.4rem;
          }
          &.ga-filter-list-save{
            margin-top: 15px;
            background: $color-secondary-light;
          }
        }
        &>a{
          /*border: $border-thick solid transparent;*/
          border: none;
          border-radius: 5px;
          display: initial;
          padding: 0;
          i{
            line-height: inherit;
            &.fa-plus-circle:before{
              font-family: "FontAwesome";
              content:"\f067";
            }
          }
        }
        .dropdown-menu{
          border:1px solid black;
          padding: 5px 7px;
          background: $color-secondary-light;
          &.ui-sortable{
            max-height: 50vh;
            overflow-y: scroll;
            li:last-child{
              margin: 15px 0 10px 0;
              a{
                background: $color-primary;
                border:none;
                border-radius: 5px;
                color: $color-primary-dark;
                font-weight: 400;
                &:hover{
                  background: $color-primary-hover;
                }
              }
            }
          }
          a{
            font-size: 14px;
            padding: 3px 7px 4px;
            border-radius: 5px;
            &:hover{
              background: $color-primary-hover;
              color:$color-primary-dark !important;
            }
            &:focus{
              background: transparent !important;
            }
            i{
              font-size: initial;
            }
          }
        }
       /* &.open>a{
          background: transparent;
          border: $border-thick solid $color-primary-dark;
        }*/
        &:hover{
          &>a {
            color:$color-primary-hover !important;
            .badge {
              color: $color-primary-hover;
              &:after, &:before {
                color: $color-primary-hover !important;
              }
            }
          }
        }
        .badge{
          position: relative;
          background: transparent;
          color: $color-primary;
          /*border: $border-thick solid $color-primary-dark;*/
          border:none;
          padding: 5px 8px;
          border-radius: 15px;
          display: table-cell;
          -webkit-transition: opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -moz-transition:    opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -o-transition:      opacity .1s ease-in-out, margin-top .3s ease-in-out;
          -ms-transition:     opacity .1s ease-in-out, margin-top .3s ease-in-out;
          transition:         opacity .1s ease-in-out, margin-top .3s ease-in-out;
          &:after{
            position: absolute;
            top:48%;
            left: 10%;
            transform: translate(0, -50%);
            color: $color-primary-dark;
            content: '(';
          }
          &:before{
            position: absolute;
            top:48%;
            right: 10%;
            transform: translate(0, -50%);
            color: $color-primary-dark;
            content: ')';
          }
        }
        b.caret{
          display: none;
        }
      }
    }
    div.navbar-left{
      .btn-group{
        a{
          font-size:0;
          i{
            margin-left: 0;
          }
        }
      }
    }
  }
  .content{
    padding: 0px 80px 80px 80px;
    .flex-item {
      flex-basis: calc(25% - 40px);
      margin: 20px;

      .box-header{
        padding: 15px 10px;
        /*background: $color-secondary;*/

        .box-title{
          position: relative;
          color: $color-primary-dark;
          font-weight: $font-weight-bold;
          font-size: $font-size-dashboard-headline;
          letter-spacing: 0.3px;
        }

        &:before{
          position: absolute;
          content: " ";
          display: initial;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          box-shadow: 0 -1px 15px rgba(0, 0, 0, 0.15);
        }
      }

      .box{
        background: $color-secondary-light;
        box-shadow: none;
        border-top:0;
        overflow: hidden;
      }
      .box-body {
        padding-top: 20px;
        li{
          margin-left: 10px;
          a{
            color:$color-primary-dark;
            font-weight: 400;
            font-size: $font-size-dashboard-content;
            letter-spacing: 0.3px;
          }
          :before{
            position: relative;
            font-family: "FontAwesome";
            content:"\f105";
            color: $color-primary-dark;
            left: -8px;
            font-size: 1.8rem;
          }
          .badge{
            padding: 6px 9px;
            border-radius: 30px;
            &:before{
              display: none;
            }
          }
        }
      }
    }
    form {
      .box{
        &.box-solid{
          &.box-primary{
            .box-header {
              /*background: $color-secondary-light;*/
              background: transparent;
              color: $color-primary-dark;
            }
          }
        }
      }
      .box-header{
        text-align: left;
        padding-left: 0;
        padding-right: 0;
      }
      .box-body{
        padding-left: 0;
        padding-right: 0;
      }
    }
    .sonata-filter-form{
      .form-group{
        text-align: right;
        margin-right: -10px;
      }
    }
    .form-group{
      margin-bottom:20px;
      position: relative;
      .sonata-ba-field {
        a {
          color: $color-primary;

          &:hover {
            color: $color-primary-hover;
          }
        }
      }
      /*&:active, &:focus, &:visited{
        label{
          padding-left: 0;
          top: -0.4rem;
          font-size: 1rem;
        }
      }
      label{
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        padding-left: 10px;
        transition: all 0.2s ease-in-out;
      }*/
      label{
        margin-bottom: 0;
        color: $color-primary-dark;
      }
      input[type="text"], input[type="number"], input[type="url"], input[type="email"], input[type="password"]{
        background: $color-primary-light;
        border:none;
        border-bottom: $border-thick solid transparent;
        color:$color-primary-dark;
        &:focus{
          border-color: $color-primary;
        }
      }
      .input-group-addon{
        border:none;
        background: $color-primary-light;
        span.fa{
          color:$color-primary-dark;
          font-size: 1.6rem;
          &:hover{
            color:$color-primary-hover;
          }
        }
      }
      .btn-default {
        background: $color-primary-light;
        color: $color-primary-dark;
        font-weight: $font-weight-bold;
        border: $border-thick solid $color-primary-dark;
        border-radius: 5px;
        padding: 5px 9px;
        margin-top: 0;
      }
      button {
        &.btn {
          margin-top: 0;
        }
      }
      div.select2-container {
        .select2-choices{
          border: none;
          box-shadow: none;
          border-bottom: $border-thick solid $color-primary-dark;
          border-radius: 0;
          input[type="text"]{
            border-radius: 0;
            border: none;
          }
        }
        &.select2-container-multi{
          .select2-choices{
            background: $color-secondary-light;
            border-color: transparent;
          }
        }
        .select2-choice {
          color: $color-primary-dark;
          font-weight: $font-weight-bold;
          border: $border-thick solid $color-primary-dark;
          background: $color-secondary-light;
          border-radius: 5px !important;
          box-shadow: none;
        }
        &.select2-dropdown-open .select2-choice{
          border-radius: 5px 5px 0 0 !important;
          border-bottom: 1px solid $color-primary-dark;
        }
      }
      a {
        &.sonata-toggle-filter{
          i{
            color:$color-primary-dark;
          }
        }
        &:hover{
          i{
            color:$color-primary-hover;
          }
        }
        &[data-toggle="advanced-filter"] {
          color: $color-primary-dark;
          margin-top: 0;
        }
      }
      .field-short-description{
        border: $border-thick solid $color-primary-dark;
        background: $color-primary-light;
        a{
          color:$color-primary-dark;
          font-weight: $font-weight-bold;
        }
      }
      .sonata-media-row{
        .pull-left{
          img{
             word-break: break-word;
             margin: 0 0px 15px 0;
             &.media-object{
               word-break: break-word;
               margin: 0 0px 15px 0;
             }
          }
        }
      }
    }
    /*.col-xs-6.col-md-3{
      background: $color-secondary-light ;
      padding: 13px;
    }*/
    .btn-group{
      border-radius: 5px !important;
      &.open{
        border-radius: 5px 5px 0 0 !important;
        button{
          &.dropdown-toggle{
            box-shadow: none;
            border-radius: 5px 5px 0 0;
          }
        }
      }
      button{
        &.dropdown-toggle{
          border-radius: 5px;
        }
      }
    }
    ul{
      position: relative;
      /*&.dropdown-menu{
        position: absolute;
      }*/
      &.dropdown-menu{
        position: absolute;
        margin-top: 0;
        border:1px solid black;
        padding: 5px 7px;
        border-radius: 0 0 5px 5px;
        background: $color-secondary-light;
        a{
          font-size: 14px;
          padding: 3px 7px 4px;
          border-radius: 5px;
          &:hover{
            background: $color-primary-hover;
            color:$color-primary-dark !important;
          }
          i{
            font-size: initial;
          }
        }
      }
      &.sonata-tree {
        overflow: visible;
        li{
          div {
            .fa-caret-right{
              color: $color-primary-dark;
              font-size: 18px;
              left: -25px;
              margin-top: 0;
              -webkit-transition: all .25s ease-in-out;
              -moz-transition:    all .25s ease-in-out;
              -o-transition:      all .25s ease-in-out;
              -ms-transition:     all .25s ease-in-out;
              transition:         all .25s ease-in-out;
              &:before{
                content: "\f105" !important;
              }
            }
          }
        }
        .sonata-tree__item {
          border-radius: 5px;
          padding: 5px 9px;
          background: $color-secondary-light;
          /*border: none;
          box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);*/
          border: 1px solid $color-primary-light;
          &.is-active{
            background: $color-primary;
            color:$color-primary-dark;
            border: 1px solid transparent;
            &:hover{
              background: $color-primary-hover;
              color:$color-primary-dark;
            }
            a{
              &:hover{
                color:$color-primary-dark !important;
              }
            }
          }
          &:hover{
            background: rgba(0,0,0,0.05);
          }
          a {
            color: $color-primary-dark;
            font-weight: $font-weight-bold;
          }
          &:after {
            display: none;
          }
          &:before{
            display: none;
          }
        }
      }
    }


    .box-body{
      .sonata-ba-collapsed-fields {
        a {
          &.btn {
            &.sonata-ba-action {
              background: $color-primary-light ;
              border:$border-thick solid $color-primary-dark;
              color: $color-primary-dark;
              font-weight: $font-weight-bold;
              border-radius: 5px;
              margin-right: 4px;
              &:last-child{
                margin-right: 0;
              }
              &.btn-danger{
                color:$color-primary-dark;
                &:hover{
                  border-color: $color-primary-dark;
                  color:$color-primary-hover !important;
                  //color:rgba(255,0,0,0.8) !important;
                }
              }
            }
          }
        }
      }
    }
    .sonata-ba-form{
      .sonata-ba-form-actions {
        background: $color-primary-light;
        border: none;
        box-shadow: none;
        &.well{
          padding-left: 0px;
          color: $color-primary-dark;
          font-weight: $font-weight-bold;
          &.stuck{
            margin-left: 0px;
          }
        }
        button{
          &.btn{
            background: $color-primary-light ;
            border:$border-thick solid $color-primary-dark;
            color: $color-primary-dark;
            font-weight: $font-weight-bold;
            border-radius:5px;
            &:hover{
              color: $color-primary-hover;
            }
          }
        }
        a{
          &.btn-danger{
            color:$color-primary-dark;
            background: $color-primary-light;
            border: $border-thick solid $color-primary-dark;
            font-weight: $font-weight-bold;
            &:hover{
              border-color: $color-primary-dark;
              color:$color-primary-hover !important;
              //color:rgba(255,0,0,0.8) !important;
            }
          }
        }
      }
      .tab-content{
        padding-left: 0;
        padding-right: 0;
        .box-header{
          padding-left: 0;
        }
        .box-body{
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .nav-tabs-custom{
      background: $color-primary-light;
      &> .tab-content{
        background: $color-primary-light;
        .box{
          background: $color-primary-light;
        }
      }
      div#form_attendingSoiree{
        label{
          &:nth-child(1n+2){
            margin-left: 15px;
          }
        }
      }
    }
    .box{
      background: $color-primary-light;
      .box-footer{
        background: $color-primary-light;
      }
    }
    .fair-select-wrapper{
      .select2-container{
        a{
          background: $color-secondary-light;
          border-color: $color-primary-dark;
          border-radius: 5px !important;
          .select2-chosen{
            color: $color-primary-dark;
          }
        }
        .select2-choice{
          box-shadow: none !important;
        }
      }
    }
  }
  .text-danger{
    color: $color-label-danger;
  }
}

@-webkit-keyframes alert-fade-in {
  0% {
    top: -20px;
    opacity: 0;
  }
  100% {
    top: 10px;
    opacity: 1;
  }
}

@-o-keyframes alert-fade-in {
  0% {
    top: -20px;
    opacity: 0;
  }
  100% {
    top: 10px;
    opacity: 1;
  }
}

@-ms-keyframes alert-fade-in {
  0% {
    top: -20px;
    opacity: 0;
  }
  100% {
    top: 10px;
    opacity: 1;
  }
}

@-moz-keyframes alert-fade-in {
  0% {
    top: -20px;
    opacity: 0;
  }
  100% {
    top: 10px;
    opacity: 1;
  }
}

@keyframes alert-fade-in {
  0% {
    top: -20px;
    opacity: 0;
  }
  100% {
    top: 10px;
    opacity: 1;
  }
}